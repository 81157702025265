import {
  Button,
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";

import styles from "./MessageModal.module.scss";
interface IMessageModal {
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
  title: string;
  message: string;
  options: IModalOption[];
}
interface IModalOption {
  text: string;
  method: () => void;
}



const MessageModal = ({
  isOpen,
  setIsOpen,
  title,
  message,
  options = [{ text: "Close", method: () => setIsOpen }],
}: IMessageModal) => {


  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      className={styles.message_modal_container}
    >
      <DialogBackdrop className={styles.overlay} />

      <div className={styles.modal_container}>
        <DialogTitle className={styles.dialog_title}>
          {title}
          <div className={styles.close_icon}>
            <XMarkIcon />
          </div>
        </DialogTitle>
        <div className={styles.horizontal_separator} />
        <DialogPanel className={styles.dialog}>
          <p className={styles.message}>{message}</p>
          {options.length > 0 && (
            <div className={styles.options}>
              {options.map((option, i) => (
                <Button
                  as="div"
                  className={styles.option}
                  key={crypto.randomUUID()}
                  onClick={option.method}
                >
                  {option.text}
                </Button>
              ))}
            </div>
          )}
        </DialogPanel>
      </div>
    </Dialog>
  );
};

export default MessageModal;

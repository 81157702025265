import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { setSelectedAgent } from '../slices/agentsSlice';
import { useLazyInitializeAgentQuery, useLazyTerminateAgentQuery } from '../api/agentApi';
import { IAgentResponse } from '../types/IAgents';
import { RootState } from '../store/store';
import useLoading from './useLoading';
import { useTranslation } from 'react-i18next';
import { IError } from '../types/IErrors';
import { hasDataProperty } from '../utils/checkError';

const useAgentManagement = () => {
  const dispatch = useDispatch();
  const tenant_id = localStorage.getItem("tenant_id") ?? "";
  const selectedAgent = useSelector((state: RootState) => state.agents.selectedAgent);
  const [terminateAgent, { isFetching: terminateFetching }] = useLazyTerminateAgentQuery();
  const [initializeAgent, { isFetching: initializeFetching }] = useLazyInitializeAgentQuery();
  const { t } = useTranslation()

  useLoading('initialize', initializeFetching);
  useLoading('terminate', terminateFetching);

  const handleTerminate = useCallback(async () => {
    if (selectedAgent && selectedAgent.id.length > 0) {
      try {
        await terminateAgent({ tenant_id: tenant_id, agent_id: selectedAgent.id, user_filter: '' }).unwrap();
        dispatch(setSelectedAgent(null));
      } catch (error) {
        if (hasDataProperty(error)) {
          const typedError = error as IError;
          if (typedError.data.message) {
            toast.error(t(`errors.${typedError.data.code}`));
            toast.warning(`Details: ${typedError.data.message}`);
          } else {
            toast.error(t(`errors.${typedError.data.code}`));
          }
        } else {
          toast.error(`${t("errors.generic")}`);
        }
      }
    }
  }, [tenant_id, terminateAgent, dispatch, selectedAgent, t]);

  const agentHandler = useCallback(async (agent: IAgentResponse) => {
    try {
      // Se l'agente selezionato è lo stesso, non fare nulla
      let isSameAgent: boolean = selectedAgent
        ? Object.keys(selectedAgent)
          .every(key => selectedAgent[key as keyof IAgentResponse] === agent[key as keyof IAgentResponse]) : false;


      if (isSameAgent) {
        toast.info(`Agent ${agent.name} is already initialized.`);
        return;
      }

      // Deallocare l'agente precedente se è diverso
      if (selectedAgent) {
        await handleTerminate();
      }

      // Allocare il nuovo agente
      const result = await initializeAgent({ tenant_id: tenant_id, agent_id: agent.id, user_filter: '' });

      if (result.isError) {
        if (result.error && 'status' in result.error && result.error.status !== 401 && result.error.status !== 403) {
          toast.warning(`Agent ${agent.name} is not ready yet. Please try again in 5 minutes.`);
        }
      } else {
        if (selectedAgent?.id !== agent.id) {
          toast.success(`Agent ${agent.name} initialized`);
        }
        dispatch(setSelectedAgent(agent));
      }
    } catch (error) {
      console.error('Error allocating agent:', error);
      toast.error('Failed to initialize the agent. Please try again.');
    }
  }, [tenant_id, initializeAgent, dispatch, handleTerminate, selectedAgent]);


  const handleAgentModification = useCallback(async (modifiedAgent: IAgentResponse, agents: IAgentResponse[]) => {
    if (selectedAgent && selectedAgent.id === modifiedAgent.id) {
      await agentHandler(modifiedAgent);
    }
  }, [agentHandler, selectedAgent]);

  const handleAgentDeletion = useCallback(async (deletedAgentId: string, agents: IAgentResponse[]) => {
    if (selectedAgent && selectedAgent.id === deletedAgentId) {

      if (agents.length === 0) {
        await handleTerminate();
        dispatch(setSelectedAgent(null));
      } else {
        const firstAgent = agents[0];
        await agentHandler(firstAgent);
      }
    }
  }, [agentHandler, dispatch, handleTerminate, selectedAgent]);

  return { handleTerminate, agentHandler, handleAgentModification, handleAgentDeletion };
};

export default useAgentManagement;
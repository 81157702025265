import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { toast } from "react-toastify";

import { EndpointConfig } from "../configs/EndpointConfig";
import { IReaderRequest, IReaderResponse, IReaders } from "../types/IReader";

const baseQuery = fetchBaseQuery({
  baseUrl: window._env_.REACT_APP_API_URL,
  prepareHeaders: (headers) => {
    headers.set("Content-Type", "application/json");
    const token = localStorage.getItem("access_token");

    if (token) {
      headers.set("Authorization", `${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    localStorage.clear();
    sessionStorage.clear();
    toast.warning("Your session has expired. Please log in again.");
  }
  return result;
};

export const readerApi = createApi({
  reducerPath: "readerApi",
  tagTypes: ["readerApi"],
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getReader: builder.query<IReaderResponse, string>({
      // get readers by reader_id
      query: (reader_id) => {
        return {
          url: `/${EndpointConfig.READER_URL}/${reader_id}`,
          method: "GET",
        };
      },
      providesTags: ["readerApi"],
    }),
    getReadersByTenant: builder.query<IReaders, string>({
      // get readers by tenant_id
      query: (tenant_id) => {
        return {
          url: `/${EndpointConfig.READER_URL}`,
          params: {tenant_id: tenant_id},
          method: "GET",
        };
      },
      transformResponse: (response: IReaders) => {
        const modifiedResponse: IReaders = {
          data: response.data.map((reader: any) => ({
            ...reader,
            conf: {
              ...reader.conf,
              type: reader.source_type,
            },
          })),
        };
        return modifiedResponse;
      },
      providesTags: ["readerApi"],
    }),
    createReader: builder.mutation<string, IReaderRequest>({
      query: (params) => {
        const { id, ...props } = params;
        return {
          url: `/${EndpointConfig.READER_URL}`,
          method: "POST",
          body: props,
        };
      },
      invalidatesTags: ["readerApi"],
    }),
    updateReader: builder.mutation<string, IReaderRequest>({
      query: (params) => ({
        url: `/${EndpointConfig.READER_URL}/${params.id}`,
        method: "PUT",
        body: params,
      }),
      invalidatesTags: ["readerApi"],
    }),
    deleteReader: builder.mutation<string, string>({
      query: (reader_id) => ({
        url: `/${EndpointConfig.READER_URL}/${reader_id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["readerApi"],
    }),
  }),
});

export const {
  useGetReadersByTenantQuery,
  useCreateReaderMutation,
  useUpdateReaderMutation,
  useDeleteReaderMutation,
} = readerApi;

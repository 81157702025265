export const en = {
    signinPage: {
        welcome: "Welcome to",
        title: "Aitho Rag Enterprise",
        signin: "Sign In",
        signinDescription: "Welcome to Aitho Rag Enterprise.\nSign in with your credentials to try it out.",
        buttonSignin: "Sign In",
    },
    dashboardPage: {

    },
    errors: {
        generic: "Oops! Something went wrong.",
        "A-001": "Agent Not Initialized Error",
        "A-002": "Agent name error. Try to change the name of the agent.",
        "D-001": "Datastore Deletion With Linked Object Error",
        "I-001": "Ingestion In Progress Error",
        "O-001": "Object Creation Error",
        "O-002": "Object Update Error",
        "O-003": "Object Not Found Error",
        "O-004": "Object Connection Error",
        "R-001": "Reader Not Initialized Error",
        "R-002": "Reader Deletion With Linked Object Error",
        "R-003": "Reader Connection Error",
        "R-004": "Reader Validation Error",
        "R-005": "Reader Generic Error",
    }
}
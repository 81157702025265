import { useCallback, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Label, Field, Description, Button } from "@headlessui/react";
import { toast } from "react-toastify";

import { useLazyGetTenantQuery } from "../../api/tenantApi";
import { useAuth } from "../../hooks/useAuth";
import { RoutesEnum } from "../../configs/routesEnum";
import { useAppDispatch } from "../../store/store";
import { resetModalState } from "../../slices/modalsSlice";

import styles from "./SigninPage.module.scss";


const SigninPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const vendor_id = localStorage.getItem('vendor_id') ?? '';

  const { initializeSignIn, isAuth, googleLogin } = useAuth();
  const [getTenant] = useLazyGetTenantQuery();

  const microsoftSignIn = useCallback(async () => {
    await initializeSignIn()
  }, [initializeSignIn])
  const googleSignIn = useCallback(() => {
    googleLogin()
  }, [googleLogin])

  const routingByFirstAccess = useCallback(async () => {
    const response = await getTenant(vendor_id).unwrap();

    try {
      if (response.data.is_wizard_complete) {
        navigate(RoutesEnum.DASHBOARD);
      } else {
        navigate(RoutesEnum.WIZARD);
      }
    }
    catch (error) {
      toast.error(`Error in Get Tenant ${error}`)
      navigate(RoutesEnum.WELCOME_PAGE);
    }
  }, [getTenant, navigate, vendor_id]);

  useEffect(() => {
    if (isAuth) {
      routingByFirstAccess();
    } else {
      dispatch(resetModalState());
    }
  }, [dispatch, isAuth, routingByFirstAccess]);

  return (
    <div className={styles.signinpage_container}>
      <div className={styles.content_container}>
        <p> Welcome to</p>
        <h1>
          <span className={styles.highlight_h1}>A</span>itho Rag Enterprise
        </h1>
      </div>
      <Field as="div" className={styles.form_container}>
        <Label className={styles.form_label}>Sign in</Label>
        <Description className={styles.form_description}>
          Welcome to Aitho Rag Enterprise. <br />
          Signin with your credentials to experience.
        </Description>
        <Button className={styles.button} onClick={microsoftSignIn}>
          <span className={styles.button_text}>Microsoft Sign In</span>
          <div className={styles.arrow_wrapper}>
            <div className={styles.arrow}></div>
          </div>
        </Button>
        <Button className={styles.button} onClick={googleSignIn}>
          <span className={styles.button_text}>Google Sign In</span>
          <div className={styles.arrow_wrapper}>
            <div className={styles.arrow}></div>
          </div>
        </Button>
      </Field>
      <Outlet />
    </div>
  );
};

export default SigninPage;

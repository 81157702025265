import { useState, useCallback, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { ArrowLeftStartOnRectangleIcon, Cog6ToothIcon  } from "@heroicons/react/24/solid";
import { Button } from "@headlessui/react";
import {  useSelector } from "react-redux";

import { useAuth } from '../../hooks/useAuth'
import { IGoogleUserDetails, UserDetailsType } from "../../types/IUser";
import { RootState } from "../../store/store";
import continueLogo from '../../assets/ContinueDev.svg'

import styles from "./UserDetails.module.scss";

type IUserDetails = { 
  isHidden: boolean; 
  hiddenFunction: () => void 
  handleSettings: (modalName: string) => void;
};

const UserDetails = ({ isHidden, hiddenFunction, handleSettings }: IUserDetails) => {
  const selectedAgent = useSelector((state: RootState) => state.agents.selectedAgent);
  const [userDetails, setUserDetails] = useState<UserDetailsType | null>(null);

  const { accounts } = useMsal();
  const { handleSignout } = useAuth();

  const authProvider = localStorage.getItem('authProvider');

  useEffect(() => {
      if (authProvider === 'google') {
          const userInfoString = localStorage.getItem('userInfo');
          if (userInfoString) {
              const googleUserInfo = JSON.parse(userInfoString) as IGoogleUserDetails;
              setUserDetails(googleUserInfo);
          }
      } else if (authProvider === 'microsoft') {
          setUserDetails(accounts);
      } else {
          setUserDetails(null); // Ensure state is reset
      }
  }, [authProvider, accounts]);

  const signout = useCallback(async () => {
    await handleSignout(accounts[0])
  },[accounts, handleSignout])

  const getUserInitials = useCallback((name: string) => {
      const nameParts = name.split(' ');
      return nameParts.length > 1
          ? `${nameParts[0][0]}${nameParts[1][0]}`
          : nameParts[0][0];
  }, []);

  const renderUserDetails = useCallback(() => {
      if (!userDetails) {
          return <p>No user details available</p>;
      }

      if (Array.isArray(userDetails)) {
          // MSAL AccountInfo[]
          const msAccount = userDetails[0];
          return (
              <div className={styles.icon_wrapper}>
                  <div className={styles.icon}>
                      {getUserInitials(msAccount?.name ?? '')}
                  </div>
                  <div className={styles.name_email}>
                      <div className={styles.name}>{msAccount?.name}</div>
                      <div className={styles.email}>{msAccount?.username}</div>
                  </div>
              </div>
          );
      } else {
          return (
              <div className={styles.icon_wrapper}>
                  <div className={styles.icon}>
                      {getUserInitials(userDetails?.name ?? '')}
                  </div>
                  <div className={styles.name_email}>
                      <div className={styles.name}>{userDetails?.name}</div>
                      <div className={styles.email}>{userDetails?.email}</div>
                  </div>
              </div>
          );
      }
  }, [getUserInitials, userDetails]);

  return (
      <div className={`${styles.user_details} ${isHidden ? styles.hidden : ''}`}>
          <div className={styles.user}>
              {renderUserDetails()}
          </div>
          {selectedAgent && 
            <Button className={styles.button} onClick={() => handleSettings('continue')}>
            Continue.Dev
                <img src={continueLogo} alt="ContinuDeveLogo" className={styles.logo} />
            </Button>
        }
          <Button className={styles.button} onClick={() => handleSettings('settings')}>
              Enterprise Settings
              <div className={styles.button_icon}>
                  <Cog6ToothIcon />
              </div>
          </Button>
          <Button className={styles.button} onClick={signout}>
              Logout
              <div className={styles.button_icon}>
                  <ArrowLeftStartOnRectangleIcon />
              </div>
          </Button>
      </div>
  );
};

export default UserDetails;
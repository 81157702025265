import { MouseEvent, useCallback } from "react";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/react";
import { toast } from "react-toastify";
import { TrashIcon, PencilIcon, PlayIcon } from "@heroicons/react/24/solid";

import {
  useDeleteIngesterMutation,
  useLazyPerformIngestionQuery,
} from "../../../../api/ingesterApi";
import { useGetDatastoresByTenantQuery } from "../../../../api/datastoreApi";
import { useGetReadersByTenantQuery } from "../../../../api/readerApi";
import Spinner from "../../../Spinner";
import { FormMode } from "../../../../configs/FormMode";
import { IIngesterResponse } from "../../../../types/IIngester";

import styles from "../../List.module.scss";
import { useTranslation } from "react-i18next";
import { IError } from "../../../../types/IErrors";
import { hasDataProperty } from "../../../../utils/checkError";

interface IIngestersList {
  setFormMode: (mode: FormMode) => void;
  setSelectedIngester: (reader: IIngesterResponse) => void;
  loadingState: {
    loading: boolean;
    id: string | null;
  };
  setLoadingState: (state: { loading: boolean; id: string | null }) => void;
  handleTabChange: (index: number) => void;
  sortedData: IIngesterResponse[];
}

const IngestersList = ({
  setFormMode,
  setSelectedIngester,
  loadingState,
  setLoadingState,
  handleTabChange,
  sortedData
}: IIngestersList) => {
  const tenant_id = localStorage.getItem("tenant_id") ?? "";

  const { data: dataStore } = useGetDatastoresByTenantQuery(tenant_id);
  const { data: readers } = useGetReadersByTenantQuery(tenant_id);

  const [performIngestion] = useLazyPerformIngestionQuery();
  const [deleteIngester] = useDeleteIngesterMutation();
  const { t } = useTranslation()


  const performIngestionFunc = useCallback(async (e: MouseEvent<HTMLButtonElement>, ingester: IIngesterResponse) => {
    e.stopPropagation();

    try {
      await performIngestion(ingester.id).unwrap();
      toast.success(`Ingestion performed successfully for ingester: ${ingester.name}`);

    } catch (error) {
      // console.error(`Failed to perform ingestion for ingester ID: ${ingester.id}`,error);
      // toast.warning(`Failed to perform ingestion for ingester: ${ingester.name}`);
      if (hasDataProperty(error)) {
        const typedError = error as IError;
        if (typedError.data.message) {
          toast.error(t(`errors.${typedError.data.code}`));
          toast.warning(`Details: ${typedError.data.message}`);
        } else {
          toast.error(t(`errors.${typedError.data.code}`));
        }
      } else {
        toast.error(`${t("errors.generic")}`);
      }
    }
  }, [performIngestion, t]);

  const modifyIngesterFunc = useCallback((e: MouseEvent<HTMLButtonElement>, ingester: IIngesterResponse) => {
    e.stopPropagation();

    setSelectedIngester(ingester);
    setFormMode(FormMode.EDIT);
  }, [setFormMode, setSelectedIngester]);

  const deleteIngesterFunc = useCallback(
    async (e: MouseEvent<HTMLButtonElement>, ingesterId: string) => {
      e.stopPropagation();
      setLoadingState({ loading: true, id: ingesterId });
      try {
        await deleteIngester(ingesterId);
        toast.success("Ingester removed");
      }catch (error) {
        if (hasDataProperty(error)) {
          const typedError = error as IError;
          if (typedError.data.message) {
            toast.error(t(`errors.${typedError.data.code}`));
            toast.warning(`Details: ${typedError.data.message}`);
          } else {
            toast.error(t(`errors.${typedError.data.code}`));
          }
        } else {
          toast.error(`${t("errors.generic")}`);
        }
      }
    }, [deleteIngester, setLoadingState, t]);

  return (
    <div className={styles.list_container}>
      {(sortedData?.length ?? 0) > 0 ? (
        sortedData.map((ingester) => (
          <div className={styles.disclosure_container} key={ingester.id}>
            <Disclosure key={crypto.randomUUID()}>
              <DisclosureButton className={styles.disclosure_button} as="div">
                <span className={styles.disclosure_title}>{ingester.name}</span>

                {!ingester.performing_ingestion &&
                  (!loadingState.loading || loadingState.id !== ingester.id) ? (
                  <div className={styles.disclosure_controls}>
                    <button
                      onClick={(e) => performIngestionFunc(e, ingester)}
                      title="Ingest"
                    >
                      <PlayIcon width={20} />
                    </button>
                    <button
                      onClick={(e) => modifyIngesterFunc(e, ingester)}
                      title="Modify"
                    >
                      <PencilIcon width={20} />
                    </button>
                    <button
                      onClick={(e) => deleteIngesterFunc(e, ingester.id)}
                      title="Remove"
                    >
                      <TrashIcon width={20} />
                    </button>
                  </div>
                ) : (
                  <Spinner />
                )}
              </DisclosureButton>
              <DisclosurePanel className={styles.disclosure_panel}>
                <ul>
                  <li>
                    <span className={styles.field_title}>Id:</span>{" "}
                    {ingester.id}
                  </li>
                  <li>
                    <span className={styles.field_title}>Description: </span>
                    {ingester.description}
                  </li>
                  <li>
                    <span className={styles.field_title}>Data Origin: </span>
                    {readers?.data.map(
                      (reader) =>
                        reader.id === ingester.reader_id.id && (
                          <span key={crypto.randomUUID()}>{reader.name}</span>
                        )
                    )}
                  </li>
                  <li>
                    <span className={styles.field_title}>Datastore: </span>
                    {dataStore?.data.map(
                      (store) =>
                        store.id === ingester.datastore_id.id && (
                          <span key={crypto.randomUUID()}>{store.name}</span>
                        )
                    )}
                  </li>
                </ul>
              </DisclosurePanel>
            </Disclosure>
          </div>
        ))
      ) : (
        <>
          {(readers?.data.length ?? 0) > 0 &&
            (dataStore?.data.length ?? 0) > 0 && (
              <p className={styles.no_data}>
                No Ingesters available. Click on "Create" to add a Ingester.{" "}
              </p>
            )}

          {(readers?.data.length ?? 0) === 0 &&
            (dataStore?.data.length ?? 0) > 0 && (
              <p className={styles.no_data}>
                You can't create an Ingester without a DataOrigin.{" "}
                <span
                  className={styles.tab_link}
                  onClick={() => handleTabChange(1)}
                >
                  Add
                </span>{" "}
                one first!
              </p>
            )}
          {(readers?.data.length ?? 0) > 0 &&
            (dataStore?.data.length ?? 0) === 0 && (
              <p className={styles.no_data}>
                You can't create an Ingester without a DataStore.{" "}
                <span
                  className={styles.tab_link}
                  onClick={() => handleTabChange(2)}
                >
                  Add
                </span>{" "}
                one first!
              </p>
            )}
          {(readers?.data.length ?? 0) === 0 &&
            (dataStore?.data.length ?? 0) === 0 && (
              <p className={styles.no_data}>
                You can't create an Ingester without a DataOrigin and a
                DataStore. Add a{" "}
                <span
                  className={styles.tab_link}
                  onClick={() => handleTabChange(2)}
                >
                  DataOrigin
                </span>{" "}
                and a{" "}
                <span
                  className={styles.tab_link}
                  onClick={() => handleTabChange(2)}
                >
                  DataStore
                </span>{" "}
                first!
              </p>
            )}
        </>
      )}
    </div>
  );
};

export default IngestersList;

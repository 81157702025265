export const initialTabs: ITab[] = [
    {
        id: 0,
        name: "readers",
        completed: false,
        label: "DataOrigin",
        isSetted: false,
    },
    {
        id: 1,
        name: "datastores",
        completed: false,
        label: "DataStore",
        isSetted: false,
    },
    {
        id: 2,
        name: "ingesters",
        completed: false,
        label: "Ingesters",
        isSetted: false,
    },
    {
        id: 3,
        name: "agents",
        completed: false,
        label: "Agents",
        isSetted: false,
    },
];

export interface ITab {
    id: number;
    name: string;
    completed: boolean;
    label: string;
    isSetted: boolean;
}
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { IGoogleUserDetails } from "../types/IUser";

export const googleApi = createApi({
    reducerPath: "googleApi",
    baseQuery: fetchBaseQuery({ baseUrl: 'https://www.googleapis.com/' }),
    endpoints: (builder) => ({
        getGoogleUserInfo: builder.query<IGoogleUserDetails, string>({
            query: (accessToken) => ({
                url: '/oauth2/v3/userinfo',
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
            }),
        }),
    }),
})

export const { useLazyGetGoogleUserInfoQuery } = googleApi;
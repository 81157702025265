import { MouseEvent, useCallback } from "react";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/react";
import { toast } from "react-toastify";
import { TrashIcon, PencilIcon } from "@heroicons/react/24/solid";

import { useDeleteAgentMutation } from "../../../../api/agentApi";
import Spinner from "../../../Spinner";
import { IAgentResponse } from "../../../../types/IAgents";
import { FormMode } from "../../../../configs/FormMode";
import { useGetDatastoresByTenantQuery } from "../../../../api/datastoreApi";

import styles from "../../List.module.scss";
import { useTranslation } from "react-i18next";
import { IError } from "../../../../types/IErrors";
import { hasDataProperty } from "../../../../utils/checkError";

interface IAgentsList {
  setFormMode: (mode: FormMode) => void;
  setSelectedAgent: (agent: IAgentResponse) => void;
  loadingState: {
    loading: boolean;
    id: string | null;
  };
  setLoadingState: (state: { loading: boolean; id: string | null }) => void;
  handleTabChange: (index: number) => void;
  sortedData: IAgentResponse[];
}

const AgentsList = ({ setFormMode, setSelectedAgent, loadingState, setLoadingState, handleTabChange, sortedData }: IAgentsList) => {
  const tenant_id = localStorage.getItem("tenant_id") ?? "";

  const { data: dataStore } = useGetDatastoresByTenantQuery(tenant_id);
  const [deleteAgent] = useDeleteAgentMutation();
  const { t } = useTranslation()
  const modifyAgentFunc = useCallback((e: MouseEvent<HTMLButtonElement>, agent: IAgentResponse) => {
    e.stopPropagation();

    setSelectedAgent(agent);
    setFormMode(FormMode.EDIT);
  }, [setFormMode, setSelectedAgent]);

  const deleteAgentFunc = useCallback(async (e: MouseEvent<HTMLButtonElement>, agentId: string) => {
    e.stopPropagation();

    setLoadingState({ loading: true, id: agentId });
    try {
      await deleteAgent(agentId).unwrap();
      toast.success("Agent removed");
    }catch (error) {
      if (hasDataProperty(error)) {
        const typedError = error as IError;
        if (typedError.data.message) {
          toast.error(t(`errors.${typedError.data.code}`));
          toast.warning(`Details: ${typedError.data.message}`);
        } else {
          toast.error(t(`errors.${typedError.data.code}`));
        }
      } else {
        toast.error(`${t("errors.generic")}`);
      }
    }
  }, [deleteAgent, setLoadingState, t]);

  return (
    <div className={styles.list_container}>
      {(sortedData?.length ?? 0) > 0 ? (
        sortedData.map((agent: IAgentResponse) => (
          <div
            className={styles.disclosure_container}
            key={crypto.randomUUID()}
          >
            <Disclosure key={crypto.randomUUID()}>
              <DisclosureButton
                as="div"
                key={crypto.randomUUID()}
                className={styles.disclosure_button}
              >
                <span className={styles.disclosure_title}>{agent.name}</span>
                {!loadingState.loading || loadingState.id !== agent.id ? (
                  <div className={styles.disclosure_controls}>
                    <button
                      onClick={(e) => modifyAgentFunc(e, agent)}
                      title="Modify"
                    >
                      <PencilIcon width={20} />
                    </button>
                    <button
                      onClick={(e) => deleteAgentFunc(e, agent.id)}
                      title="Remove"
                    >
                      <TrashIcon width={20} />
                    </button>
                  </div>
                ) : (
                  <Spinner />
                )}
              </DisclosureButton>
              <DisclosurePanel
                key={crypto.randomUUID()}
                className={styles.disclosure_panel}
              >
                <ul>
                  <li>
                    <span className={styles.field_title}>
                      AI Model Provider:
                    </span>{" "}
                    {agent.type}
                  </li>
                  <li>
                    <span className={styles.field_title}>Data Store:</span>{" "}
                    {dataStore?.data.map(
                      (store) =>
                        store.id === agent.datastore_id.id && (
                          <span key={crypto.randomUUID()}>{store.name}</span>
                        )
                    )}
                  </li>
                  <li>
                    <span className={styles.field_title}>Agent Config:</span>{" "}
                    <ul>
                      <li>
                        <span className={styles.field_title}>LLM Model:</span>{" "}
                        {agent.conf.llm_model}
                      </li>
                      <li>
                        <span className={styles.field_title}>Search type:</span>{" "}
                        {agent.conf.search_type}
                      </li>
                    </ul>
                  </li>
                </ul>
              </DisclosurePanel>
            </Disclosure>
          </div>
        ))
      ) : (
        <>
          {(dataStore?.data.length ?? 0) > 0 && (
            <p className={styles.no_data}>
              No Agents available. Click on "Create" to add a Agent.{" "}
            </p>
          )}

          {(dataStore?.data.length ?? 0) === 0 && (
            <p className={styles.no_data}>
              You can't create an Agent without a DataStore.{" "}
              <span
                className={styles.tab_link}
                onClick={() => handleTabChange(2)}
              >
                Add
              </span>{" "}
              one first!
            </p>
          )}
        </>
      )}
    </div>
  );
};

export default AgentsList;

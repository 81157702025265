import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { IUser, IUserAuth } from "../types/IUser";
import { EndpointConfig } from "../configs/EndpointConfig";

const baseQuery = fetchBaseQuery({
    baseUrl: `${window._env_.REACT_APP_API_URL}/${EndpointConfig.AUTH_URL}`,
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json");

      return headers;
    },
  });


export const userAuthApi = createApi({
    reducerPath: "userAuthApi",
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        msalAuth: builder.mutation<IUser, IUserAuth>({
            query: (props) => ({
                url: `/${EndpointConfig.MSAL_URL}`,
                method: 'POST',
                body: props
            }),
        }),
        googleAuth: builder.mutation<IUser, IUserAuth>({
            query: (props) => ({
                url: `/${EndpointConfig.GOOGLE_URL}`,
                method: 'POST',
                body: props
            }),
        }),
    }),
})

export const { useMsalAuthMutation, useGoogleAuthMutation } = userAuthApi;
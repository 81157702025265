import {
  useState,
  useRef,
  useCallback,
  ChangeEvent,
  KeyboardEvent,
} from "react";
import { Button, Field, Textarea } from "@headlessui/react";
import { PaperAirplaneIcon } from "@heroicons/react/24/solid";
import { useSelector } from "react-redux";

import { RootState } from "../../../store/store";
import { toast } from "react-toastify";
import useAutoSizeTextArea from "../../../hooks/useAutoSizeTextArea";

import styles from "./UserInput.module.scss";

interface IUserInput {
  sendMessage: (textInput: string) => void;
  isLoading: boolean;
}

const UserInput = ({ sendMessage, isLoading }: IUserInput) => {
  const [userInput, setUserInput] = useState<string>("");
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const selectedAgent = useSelector(
    (state: RootState) => state.agents.selectedAgent
  );

  useAutoSizeTextArea("textarea_input", textareaRef.current, userInput);

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      const { value } = event.target;
      setUserInput(value);
    },
    []
  );

  const sendMessageClick = useCallback(() => {
    if (selectedAgent) {
      sendMessage(userInput);
      setUserInput("");
    } else {
      toast.warning("Choose an agent first");
    }
  }, [selectedAgent, sendMessage, userInput]);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === "Enter") {
        if (!selectedAgent) {
          e.preventDefault();
          toast.warning("Choose an agent first");
        } else {
          if (e.shiftKey) {
            e.preventDefault();
            const { selectionStart, selectionEnd, value } = e.currentTarget;
            const newValue =
              value.substring(0, selectionStart) +
              "\n" +
              value.substring(selectionEnd);
            e.currentTarget.value = newValue;
            e.currentTarget.selectionStart = e.currentTarget.selectionEnd =
              selectionStart + 1;
          } else if (userInput.trim().length > 0) {
            e.preventDefault();
            sendMessage(userInput);
            setUserInput("");
          }
        }
      }
    },
    [selectedAgent, sendMessage, userInput]
  );

  return (
    <Field className={styles.textarea_container}>
      <Textarea
        className={styles.textarea}
        name="textarea"
        placeholder="Enter your message here"
        ref={textareaRef}
        onChange={handleInputChange}
        value={userInput}
        onKeyDown={handleKeyDown}
      />
      <Button
        type="submit"
        className={styles.button}
        disabled={userInput.trim().length === 0 || isLoading}
        onClick={sendMessageClick}
      >
        <PaperAirplaneIcon />
      </Button>
    </Field>
  );
};

export default UserInput;

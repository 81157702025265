export enum EndpointConfig  {
    AGENTS_URL = "agent",
    TENANT_URL = "tenant",
    CONTINUE_URL = "continue",
    INGESTER_URL = "ingester",
    READER_URL = "reader",
    DATASTORE_URL = "datastore",
    AUTH_URL = "auth",
    MSAL_URL = "microsoft",
    GOOGLE_URL = "google"
}
import { MouseEvent, useCallback } from "react";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import { toast } from "react-toastify";
import { TrashIcon, PencilIcon } from "@heroicons/react/24/solid";

import { useDeleteReaderMutation } from "../../../../api/readerApi";
import Spinner from "../../../Spinner";
import { IGiteaConf, IReaderResponse, ISharepointConf } from "../../../../types/IReader";
import { FormMode } from "../../../../configs/FormMode";

import styles from "../../List.module.scss";
import { useTranslation } from "react-i18next";
import { IError } from "../../../../types/IErrors";
import { hasDataProperty } from "../../../../utils/checkError";

interface IReadersList {
  setFormMode: (mode: FormMode) => void;
  setSelectedReader: (reader: IReaderResponse) => void;
  loadingState: {
    loading: boolean;
    id: string | null;
  };
  setLoadingState: (state: { loading: boolean; id: string | null }) => void;
  sortedData: IReaderResponse[];
}

const ReadersList = ({ setFormMode, setSelectedReader, loadingState, setLoadingState, sortedData }: IReadersList) => {
  const [deleteReader] = useDeleteReaderMutation();
  const { t } = useTranslation()

  const modifyReaderFunc = useCallback((e: MouseEvent<HTMLButtonElement>, reader: IReaderResponse) => {
    e.stopPropagation();
    setSelectedReader(reader);
    setFormMode(FormMode.EDIT);
  }, [setFormMode, setSelectedReader]);

  const deleteReaderFunc = useCallback(async (e: MouseEvent<HTMLButtonElement>, readerId: string) => {
    e.stopPropagation();
    setLoadingState({ loading: true, id: readerId });
    try {
      await deleteReader(readerId).unwrap();
      toast.success("Data Origin removed");
    } catch (error) {
      if (hasDataProperty(error)) {
        const typedError = error as IError;
        if (typedError.data.message) {
          toast.error(t(`errors.${typedError.data.code}`));
          toast.warning(`Details: ${typedError.data.message}`);
        } else {
          toast.error(t(`errors.${typedError.data.code}`));
        }
      } else {
        toast.error(`${t("errors.generic")}`);
      }
    }
    setLoadingState({loading:false, id:readerId})
  }, [deleteReader, setLoadingState, t]);


  const renderConf = useCallback((reader: IReaderResponse) => {
    return Object.keys(reader.conf)
      .filter((confKey) => confKey !== "type")
      .map((confKey, i) => (
        <li key={crypto.randomUUID()}>
          <span className={styles.field_title}>{confKey}:</span>{" "}
          {reader.conf.type === "sharepoint" &&
            reader.conf[confKey as keyof ISharepointConf]}
          {reader.conf.type === "gitea" &&
            reader.conf[confKey as keyof IGiteaConf]}
        </li>
      ));
  }, []);

  return (
    <div className={styles.list_container}>
      {(sortedData?.length ?? 0) > 0 ? (
        sortedData?.map((reader) => (
          <div
            className={styles.disclosure_container}
            key={crypto.randomUUID()}
          >
            <Disclosure key={crypto.randomUUID()}>
              <DisclosureButton
                as="div"
                key={crypto.randomUUID()}
                className={styles.disclosure_button}
              >
                <span className={styles.disclosure_title}>{reader.name}</span>
                {!loadingState.loading || loadingState.id !== reader.id ? (
                  <div className={styles.disclosure_controls}>
                    <button
                      onClick={(e) => modifyReaderFunc(e, reader)}
                      title="Modify"
                    >
                      <PencilIcon width={20} />
                    </button>
                    <button
                      onClick={(e) => deleteReaderFunc(e, reader.id)}
                      title="Remove"
                    >
                      <TrashIcon width={20} />
                    </button>
                  </div>
                ) : (
                  <Spinner />
                )}
              </DisclosureButton>
              <DisclosurePanel
                key={crypto.randomUUID()}
                className={styles.disclosure_panel}
              >
                <ul>
                  <li>
                    <span className={styles.field_title}>Id:</span> {reader.id}
                  </li>
                  <li>
                    <span className={styles.field_title}>Description:</span>{" "}
                    {reader.description}
                  </li>
                  <li>
                    <span className={styles.field_title}>Source type:</span>{" "}
                    {reader.source_type}
                  </li>
                  <li>
                    <span className={styles.field_title}>Reader Config:</span>{" "}
                    <ul>{renderConf(reader)}</ul>
                  </li>
                </ul>
              </DisclosurePanel>
            </Disclosure>
          </div>
        ))
      ) : (
        <p className={styles.no_data}>
          No Data Origins available. Click on "Create" to add a Data Origin.{" "}
        </p>
      )}
    </div>
  );
};

export default ReadersList;

import { useCallback, useEffect, useState } from "react";
import {
  Button,
  Field,
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import { Bars3Icon, ChevronDownIcon } from "@heroicons/react/24/solid";
import { useSelector } from "react-redux";

import { RootState } from "../../store/store";
import { useGetAgentByTenantQuery } from "../../api/agentApi";
import { IAgentResponse } from "../../types/IAgents";
import UserDetails from "../UserDetails/UserDetails";
import useAgentManagement from "../../hooks/useInitializeAgent";
import useLoading from "../../hooks/useLoading";

import styles from "./Sidebar.module.scss";
import { toast } from "react-toastify";

type ISidebar = {
  isOpen: boolean;
  closeSideBar: () => void;
  handleSettings: (modalName: string) => void;
};


const Sidebar = ({ isOpen, closeSideBar, handleSettings }: ISidebar) => {
  const tenant_id = localStorage.getItem("tenant_id") ?? "";
  const selectedAgent = useSelector((state: RootState) => state.agents.selectedAgent);
  const [firstAllocation, setFirstAllocation] = useState<boolean>(true)

  const { data, isLoading } = useGetAgentByTenantQuery(tenant_id, { skip: !tenant_id });
  const { agentHandler, handleAgentModification, handleAgentDeletion } = useAgentManagement();
  useLoading("agent", isLoading);

  const handleOpenedModal = useCallback((modalName: string) => {
    handleSettings(modalName)
  }, [handleSettings]);

  useEffect(() => {
    if (data && data.data.length > 0) {
      if (firstAllocation && !selectedAgent) {
        // Allocazione iniziale del primo agente se non ne è selezionato nessuno
        agentHandler(data.data[0]);
        setFirstAllocation(false);
      } else {
        const currentAgent = data.data.find(agent => agent.id === selectedAgent?.id);

        if (!currentAgent && selectedAgent) {
          // L'agente selezionato è stato cancellato
          handleAgentDeletion(selectedAgent?.id, data.data);
        } else if (JSON.stringify(currentAgent) !== JSON.stringify(selectedAgent) && currentAgent) {
          // L'agente selezionato è stato modificato
          handleAgentModification(currentAgent, data.data);
        }
      }
    } else if (data && data.data.length === 0) {
      // Gestione della lista vuota
      toast.info('No agents available. Create one by "Enterprise Settings"');
      !firstAllocation && setFirstAllocation(true);

      if (selectedAgent) {
        handleAgentDeletion(selectedAgent.id, data.data);
      }
    }
  }, [agentHandler, data, selectedAgent, firstAllocation, handleAgentModification, handleAgentDeletion]);


  return (
    <div className={styles.side_bar}>
      <div
        className={styles.side_bar_container}
        style={{
          left: isOpen ? 0 : -270,
        }}
      >
        <div className={styles.burger_container}>
          <Button className={styles.burger_icon} onClick={closeSideBar}>
            <Bars3Icon className={styles.burger_icon} />
          </Button>
          <h2 className={styles.burger_title}>RAG-Enterprise</h2>
        </div>
        <div className={styles.side_bar_content}>
          <Field as="div" className={styles.field}>
            <Label className={styles.label}>Choose an agent: </Label>
            <Listbox
              value={selectedAgent}
              onChange={agentHandler}
              as="div"
              className={styles.list_box}
            >
              <ListboxButton className={styles.list_button}>
                <span className={styles.agent_name}>{selectedAgent?.name}</span>
                <ChevronDownIcon className={styles.list_icon} />
              </ListboxButton>

              <ListboxOptions
                anchor="bottom start"
                className={styles.list_options}
              >
                {data?.data.map((agent: IAgentResponse) => (
                  <ListboxOption
                    key={agent.id}
                    value={agent}
                    className={styles.list_option}
                  >
                    {agent.name}
                  </ListboxOption>
                ))}
              </ListboxOptions>
            </Listbox>
          </Field>
        </div>
        <div className={styles.side_bar_footer}>
          <UserDetails
            hiddenFunction={() => { }}
            isHidden={false}
            handleSettings={handleOpenedModal}
          ></UserDetails>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;

import { Configuration  } from "@azure/msal-browser";

export const configuration: Configuration = {
    auth: {
        clientId:  window._env_.REACT_APP_MSAL_CLIENT_ID,
        authority: window._env_.REACT_APP_MSAL_AUTHORITY,
        redirectUri: window._env_.REACT_APP_MSAL_REDIRECT_URI,
    },
};




import { MouseEvent, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { FormMode } from "../configs/FormMode";

import { RootState } from "../store/store";
import { ModalsState, setIsOpenForMessageFun } from "../slices/modalsSlice";

interface ISettingsUtils {
    formMode: string;
    setFormMode: (mode: string) => void;
}
interface IUseHandleUpdateFeedbackParams {
    isSuccess: boolean;
    isError: boolean;
    successMessage: string;
    errorMessage?: string;
    setLoadingState: (state: { loading: boolean; id: null }) => void;
  }

  

export const useHandleCreating = ({ formMode, setFormMode }: ISettingsUtils) => {
    const dispatch = useDispatch();
    const modals = useSelector((state: RootState) => state.modals);

    return useCallback((e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();

        if (formMode === FormMode.NONE) {
            setFormMode(FormMode.CREATE);
        } else if (
            modals["settings" as keyof ModalsState].isFull &&
            modals["settings" as keyof ModalsState].isOpen
        ) {
            dispatch(setIsOpenForMessageFun({ isOpen: true, modalName: "message", yesMethod: "back" }));
        } else {
            setFormMode(FormMode.NONE);
        }
    }, [dispatch, formMode, modals, setFormMode]);
};

export const useHandleUpdateFeedback = ({
    isSuccess,
    isError,
    successMessage,
    errorMessage,
    setLoadingState,
  }: IUseHandleUpdateFeedbackParams) => {
    useEffect(() => {
      setLoadingState({ loading: false, id: null });
  
      if (isSuccess) {
        toast.success(successMessage);
      }
  
      if (isError) {
        toast.warning(errorMessage);
      }
    }, [isSuccess, isError, successMessage, errorMessage, setLoadingState]);
  };
import { useCallback, useEffect, useState } from "react";
import {
  Field,
  Fieldset,
  Input,
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Textarea,
} from "@headlessui/react";
import { useDispatch } from "react-redux";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { toast } from "react-toastify";
import { object, ValidationError } from 'yup';


import {
  IGiteaConf,
  IReader,
  IReaderRequest,
  ISharepointConf,
  initialReaderState,
} from "../../../../types/IReader";
import { FormMode } from "../../../../configs/FormMode";
import { updateSettings } from "../../../../slices/modalsSlice";
import Spinner from "../../../Spinner";
import { readerFormSchema, readerGiteaConfSchema, readerSharepointConfSchema } from "../../schema";


import styles from "../../Form.module.scss";

const sourceTypes = ["sharepoint", "gitea"];

interface IReaderForm {
  submitFun: (form: IReaderRequest) => void;
  mode: string;
  reader?: IReader;
  isLoading: boolean;
}

const ReaderForm = ({ submitFun, mode, reader, isLoading }: IReaderForm) => {
  const dispatch = useDispatch();
  const tenant_id = localStorage.getItem("tenant_id") ?? "";

  const [readerForm, setReaderForm] = useState<IReader>(mode === FormMode.EDIT && reader ? reader : initialReaderState);
  const [readerGiteaConf, setReaderGiteaConf] = useState<IGiteaConf>(mode === FormMode.EDIT && reader && reader.source_type === "gitea"
      ? (reader.conf as IGiteaConf)
      : {
          domain: "",
          token: "",
          username: "",
          org_name: "",
          repo_name: ""
        }
  );
  const [readerSharepointConf, setReaderSharepointConf] = useState<ISharepointConf>( mode === FormMode.EDIT && reader && reader.source_type === "sharepoint"
        ? (reader.conf as ISharepointConf)
        : {
            client_id: "",
            client_secret: "",
            ms_tenant_id: "",
            site_name: "",
            folder_path: "",
          }
    );

  const handleTextAreaChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const { name, value } = e.target;
      setReaderForm((prevForm) => ({ ...prevForm, [name]: value }));
    },[]);

  const handelInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;

      setReaderForm((prevForm) => ({ ...prevForm, [name]: value }));
    },[]);

  const handelInputConfigChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;

      if (readerForm.source_type === "gitea") {
        setReaderGiteaConf((prev) => ({ ...prev, [name]: value }));
      } else if (readerForm.source_type === "sharepoint") {
        setReaderSharepointConf((prev) => ({ ...prev, [name]: value }));
      }
    },
    [readerForm]
  );

  const handleSelectChange = useCallback((name: string, value: string) => {
    if (name === "source_type") {
      setReaderForm((prevForm) => ({
        ...prevForm,
        [name]: value,
        conf: {},
      }));
    } else {
      setReaderForm((prevForm) => ({
        ...prevForm,
        [name]: value,
      }));
    }
  }, []);

  const handleSubmit = useCallback(async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      await readerFormSchema.validate(readerForm, { abortEarly: false });

      const confSchema = readerForm.source_type === "gitea"
        ? object().shape({
            ...readerGiteaConfSchema.fields,
          })
        : object().shape({
            ...readerSharepointConfSchema.fields,
          });

      await confSchema.validate(
        readerForm.source_type === "gitea" ? readerGiteaConf : readerSharepointConf,
        { abortEarly: false }
      );

      const finalForm: IReaderRequest = {
        ...readerForm,
        conf: readerForm.source_type === "gitea" ? readerGiteaConf : readerSharepointConf,
        tenant_id: tenant_id,
      };

      submitFun(finalForm);
    } catch (err) {
      if (err instanceof ValidationError) {
        err.inner.forEach((error) => {
          if (error.message) {
            toast.warning(error.message);
          }
        });
      } else {
        toast.warning("Invalid JSON format");
      }
    }
  }, [tenant_id, readerGiteaConf, readerSharepointConf, readerForm, submitFun]);

  useEffect(() => {
    const hasNonEmptyForm =
      mode === FormMode.CREATE
        ? JSON.stringify(readerForm) !== JSON.stringify(initialReaderState)
        : JSON.stringify(readerForm) !== JSON.stringify(reader);

    const hasNonEmptyGiteaConf =
      mode === FormMode.EDIT && readerForm.source_type === "gitea"
        ? JSON.stringify(readerGiteaConf) !== JSON.stringify(reader?.conf)
        : Object.values(readerGiteaConf).some((value) => value !== "");

    const hasNonEmptySharepointConf =
      mode === FormMode.EDIT && readerForm.source_type === "sharepoint"
        ? JSON.stringify(readerSharepointConf) !== JSON.stringify(reader?.conf)
        : Object.values(readerSharepointConf).some((value) => value !== "");

    if (hasNonEmptyForm || hasNonEmptyGiteaConf || hasNonEmptySharepointConf) {
      dispatch(
        updateSettings({
          isFull: true,
          modalName: "settings",
          tab: "reader",
        })
      );
    } else {
      dispatch(
        updateSettings({
          isFull: false,
          modalName: "settings",
          tab: "reader",
        })
      );
    }
  }, [
    dispatch,
    mode,
    reader,
    readerForm,
    readerGiteaConf,
    readerSharepointConf,
  ]);

  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      <Fieldset as="div" className={styles.fields}>
        <Field as="div" className={styles.field}>
          <Label className={styles.label}>Name</Label>
          <Input
            className={styles.input}
            minLength={1}
            maxLength={30}
            required
            name="name"
            placeholder="DataOrigin name"
            onChange={handelInputChange}
            value={readerForm.name}
            disabled={isLoading}
          />
        </Field>
        <Field as="div" className={styles.field}>
          <Label className={styles.label}>Description</Label>
          <Textarea
            name="description"
            placeholder="DataOrigin description"
            value={readerForm.description}
            onChange={handleTextAreaChange}
            className={styles.text_area}
            minLength={1}
            maxLength={1000}
            rows={2}
            disabled={isLoading}
          />
        </Field>

        <Field as="div" className={styles.field}>
          <Label className={styles.label}>Source Type: </Label>
          <Listbox
            value={readerForm.source_type}
            onChange={(value: string) =>
              handleSelectChange("source_type", value)
            }
            as="div"
            className={styles.list_box}
          >
            <ListboxButton className={styles.list_button}>
              <span className={styles.agent_name}>
                {readerForm.source_type || "Select a source type"}
              </span>
              <ChevronDownIcon className={styles.list_icon} />
            </ListboxButton>
            <ListboxOptions
              anchor="bottom start"
              className={styles.list_options}
            >
              {sourceTypes.map((sourceType) => (
                <ListboxOption
                  key={crypto.randomUUID()}
                  value={sourceType}
                  className={styles.list_option}
                  disabled={isLoading}
                >
                  {sourceType}
                </ListboxOption>
              ))}
            </ListboxOptions>
          </Listbox>
        </Field>
        <div className={styles.jsonForm}>
          <p className={styles.jsonFormTitle}>Reader Config:</p>
          <div className={styles.jsonFields}>
            {readerForm.source_type === "gitea" && (
              <>
                <Field as="div" className={styles.field}>
                  <Label className={styles.label}>Domain:</Label>
                  <Input
                    className={styles.input}
                    minLength={1}
                    required
                    name="domain"
                    placeholder='e.g. "https://git.aitho.it"'
                    onChange={handelInputConfigChange}
                    value={readerGiteaConf.domain}
                    disabled={isLoading}
                  />
                </Field>
                <Field as="div" className={styles.field}>
                  <Label className={styles.label}>Username:</Label>
                  <Input
                    className={styles.input}
                    minLength={1}
                    required
                    name="username"
                    placeholder='e.g. "name.surname"'
                    onChange={handelInputConfigChange}
                    value={readerGiteaConf.username}
                    disabled={isLoading}
                  />
                </Field>
                <Field as="div" className={styles.field}>
                  <Label className={styles.label}>Token:</Label>
                  <Input
                    className={styles.input}
                    minLength={1}
                    required
                    name="token"
                    placeholder='Generate it on your git account'
                    onChange={handelInputConfigChange}
                    value={readerGiteaConf.token}
                    disabled={isLoading}
                  />
                </Field>
                <Field as="div" className={styles.field}>
                  <Label className={styles.label}>Organization Name:</Label>
                  <Input
                    className={styles.input}
                    minLength={1}
                    name="org_name"
                    placeholder='e.g. "AI"'
                    onChange={handelInputConfigChange}
                    value={readerGiteaConf.org_name}
                    disabled={isLoading}
                  />
                </Field>
                <Field as="div" className={styles.field}>
                  <Label className={styles.label}>Repository Name:</Label>
                  <Input
                    className={styles.input}
                    minLength={1}
                    name="repo_name"
                    placeholder='e.g. "RAG-enterprise-webapp"'
                    onChange={handelInputConfigChange}
                    value={readerGiteaConf.repo_name}
                    disabled={isLoading}
                  />
                </Field>
              </>
            )}
            {readerForm.source_type === "sharepoint" && (
              <>
                <Field as="div" className={styles.field}>
                  <Label className={styles.label}>Client id:</Label>
                  <Input
                    className={styles.input}
                    minLength={1}
                    name="client_id"
                    placeholder='e.g. "123e4567-e89b-12d3-a456-426614174000"'
                    onChange={handelInputConfigChange}
                    value={readerSharepointConf.client_id}
                    disabled={isLoading}
                    required
                  />
                </Field>
                <Field as="div" className={styles.field}>
                  <Label className={styles.label}>Client secret:</Label>
                  <Input
                    className={styles.input}
                    minLength={1}
                    name="client_secret"
                    placeholder='e.g. "aB12x~Z9kLmNp89~QwRz_8T4.vHgC3U1yA2o~Jf"'
                    onChange={handelInputConfigChange}
                    value={readerSharepointConf.client_secret}
                    disabled={isLoading}
                    required
                  />
                </Field>
                <Field as="div" className={styles.field}>
                  <Label className={styles.label}>Microsoft tenant id:</Label>
                  <Input
                    className={styles.input}
                    minLength={1}
                    name="ms_tenant_id"
                    placeholder='e.g. "123e4567-e89b-12d3-a456-426614174000"'
                    onChange={handelInputConfigChange}
                    value={readerSharepointConf.ms_tenant_id}
                    disabled={isLoading}
                    required
                  />
                </Field>
                <Field as="div" className={styles.field}>
                  <Label className={styles.label}>Site name:</Label>
                  <Input
                    className={styles.input}
                    minLength={1}
                    name="site_name"
                    placeholder='e.g. "AITHO - Reparto IT"'
                    onChange={handelInputConfigChange}
                    value={readerSharepointConf.site_name}
                    disabled={isLoading}
                  />
                </Field>
                <Field as="div" className={styles.field}>
                  <Label className={styles.label}>Folder path:</Label>
                  <Input
                    className={styles.input}
                    minLength={1}
                    name="folder_path"
                    placeholder='e.g. "Reparto IT/Learn"'
                    onChange={handelInputConfigChange}
                    value={readerSharepointConf.folder_path}
                    disabled={isLoading}
                  />
                </Field>
              </>
            )}
            {readerForm.source_type === "" && (
              <p>Select a Source type first!</p>
            )}
          </div>
        </div>
        <Field as="div" className={styles.field}>
          {isLoading ? <Spinner /> :
          <button type="submit" className={styles.submit_button}>
            Submit
          </button>}
        </Field>
      </Fieldset>
    </form>
  );
};

export default ReaderForm;

import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../store/store';
import useAgentManagement from './useInitializeAgent';

const useBrowserHandler = (initialValue: boolean): [boolean, (show: boolean) => void] => {
    const [showExitPrompt, setShowExitPrompt] = useState<boolean>(initialValue);
    const selectedAgent = useSelector((state: RootState) => state.agents.selectedAgent);
  
    const { handleTerminate } = useAgentManagement();
  
  
    useEffect(() => {
      const handleBeforeUnload = async (event: BeforeUnloadEvent) => {
        if (showExitPrompt && selectedAgent?.id) {
          event.preventDefault();
          await handleTerminate();
        }
      };
  
      // Add the event listener
      window.addEventListener('beforeunload', handleBeforeUnload);
  
      // Remove the event listener on cleanup
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }, [handleTerminate, showExitPrompt, selectedAgent]);
  
    return [showExitPrompt, setShowExitPrompt];
  };
  
  export default useBrowserHandler;
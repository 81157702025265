import { ClipboardDocumentCheckIcon } from "@heroicons/react/24/solid";
import { toast } from "react-toastify";

interface CopyToClipboardButtonProps {
  content: string;
  className?: string;
}


const CopyToClipboardButton= ({ content, className }: CopyToClipboardButtonProps) => {
  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(content);
      toast.success('Copied to clipboard');
    } catch (error) {
      toast.error('Unable to copy to clipboard:');
    }
  };

  return (
    <button className={className} onClick={handleCopy}><ClipboardDocumentCheckIcon width={24}/></button>
  );
};

export default CopyToClipboardButton;
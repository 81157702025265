import { useCallback, useState } from "react";
import { Button } from "@headlessui/react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import {
  useCreateIngesterMutation,
  useGetIngesterByTenantQuery,
  useLazyPerformIngestionQuery,
  useUpdateIngesterMutation,
} from "../../../api/ingesterApi";
import { useGetDatastoresByTenantQuery } from "../../../api/datastoreApi";
import { useGetReadersByTenantQuery } from "../../../api/readerApi";
import IngesterForm from "./IngesterForm";
import IngestersList from "./IngestersList";
import Spinner from "../../Spinner";
import { FormMode } from "../../../configs/FormMode";
import { IIngesterResponse, initialIngesterState, IIngesterRequest, sortingKeys } from "../../../types/IIngester";
import { updateSettings } from "../../../slices/modalsSlice";
import { titleCase } from "../../../utils/titleCase";
import { useHandleCreating, useHandleUpdateFeedback } from "../../../utils/settingsUtils";
import Sorting from "../../Sorting";

import styles from "../Config.module.scss";
import { useTranslation } from "react-i18next";
import { IError } from "../../../types/IErrors";
import { hasDataProperty } from "../../../utils/checkError";



interface IIngestConfig {
  handleTabChange: (index: number) => void;
  formMode: string;
  setFormMode: (mode: string) => void;
}

const IngesterConfig = ({ handleTabChange, formMode, setFormMode }: IIngestConfig) => {
  const dispatch = useDispatch();
  const tenant_id = localStorage.getItem("tenant_id") ?? "";
  const [selectedIngester, setSelectedIngester] = useState<IIngesterResponse>(initialIngesterState);
  const [loadingState, setLoadingState] = useState<{ loading: boolean; id: string | null; }>({ loading: false, id: null });
  const { isLoading: isIngesterLoading } = useGetIngesterByTenantQuery(tenant_id);
  const { isLoading: isDatastoreLoading } = useGetDatastoresByTenantQuery(tenant_id);
  const { isLoading: isReaderLoading } = useGetReadersByTenantQuery(tenant_id);
  const { data } = useGetIngesterByTenantQuery(tenant_id);
  const [createIngester, { isLoading: isCreateLoading }] = useCreateIngesterMutation();
  const [updateIngester, { isError: isUpdateError, isSuccess: isUpdateSuccess, isLoading: isUpdateLoading }] = useUpdateIngesterMutation();
  const [, { isSuccess: isIngestionSuccess, isError: isIngestionError }] = useLazyPerformIngestionQuery();
  const { data: dataStore } = useGetDatastoresByTenantQuery(tenant_id);
  const { data: readers } = useGetReadersByTenantQuery(tenant_id);
  const [sortedData, setSortedData] = useState<IIngesterResponse[]>(data?.data ?? []);
  const { t } = useTranslation()

  const handleCreating = useHandleCreating({ formMode, setFormMode });//Modal Handler
  useHandleUpdateFeedback({
    isSuccess: isUpdateSuccess,
    isError: isUpdateError,
    successMessage: "Ingester Updated",
    setLoadingState,
  });//Object Update Feedback Handler
  useHandleUpdateFeedback({
    isSuccess: isIngestionSuccess,
    isError: isIngestionError,
    successMessage: "Ingestion performed",
    errorMessage: "Error during Ingest",
    setLoadingState,
  })//Ingestion Handler



  const handleUpdateIngester = useCallback(async (updatedIngester: IIngesterRequest) => {
    setLoadingState({ loading: true, id: updatedIngester.ingester_id });
    try {
      await updateIngester(updatedIngester).unwrap();
      dispatch(updateSettings({ isFull: false, modalName: "settings", tab: "ingesters" }));
      toast.success('Ingester Updated');
      setFormMode(FormMode.NONE);
    } catch (error) {
      if (hasDataProperty(error)) {
        const typedError = error as IError;
        if (typedError.data.message) {
          toast.error(t(`errors.${typedError.data.code}`));
          toast.warning(`Details: ${typedError.data.message}`);
        } else {
          toast.error(t(`errors.${typedError.data.code}`));
        }
      } else {
        toast.error(`${t("errors.generic")}`);
      }
    }
    setLoadingState({ loading: false, id: updatedIngester.ingester_id });
  }, [dispatch, setFormMode, t, updateIngester]);

  const handleCreateIngester = useCallback(async (newIngester: IIngesterRequest) => {
    setLoadingState({ loading: true, id: newIngester.ingester_id });
    try {
      await createIngester(newIngester).unwrap();
      dispatch(updateSettings({ isFull: false, modalName: "settings", tab: "ingesters" }));
      toast.success('Ingester Created');
      setFormMode(FormMode.NONE);
    } catch (error) {
      if (hasDataProperty(error)) {
        const typedError = error as IError;
        if (typedError.data.message) {
          toast.error(t(`errors.${typedError.data.code}`));
          toast.warning(`Details: ${typedError.data.message}`);
        } else {
          toast.error(t(`errors.${typedError.data.code}`));
        }
      } else {
        toast.error(`${t("errors.generic")}`);
      }
    }
    setLoadingState({ loading: false, id: newIngester.ingester_id });
  }, [createIngester, dispatch, setFormMode, t]);

  return (
    <div className={styles.container}>
      <div className={styles.header_container}>
        <Button
          className={styles.create_button}
          onClick={handleCreating}
          disabled={
            FormMode.CREATE &&
            ((dataStore?.data.length ?? 0) === 0 ||
              (readers?.data.length ?? 0) === 0)
          }
        >
          {formMode === FormMode.CREATE || formMode === FormMode.EDIT ? (
            <div className={styles.arrow_wrapper}>
              <div className={styles.arrow}></div>
            </div>
          ) : (
            FormMode.CREATE
          )}
        </Button>
        {formMode === FormMode.NONE && (
          <Sorting
            data={data?.data}
            setSortedData={setSortedData}
            defaultSort={titleCase(sortingKeys[0])}
            options={sortingKeys}
          />
        )}
      </div>

      {(isIngesterLoading || isDatastoreLoading || isReaderLoading) && (
        <Spinner />
      )}
      {!(isIngesterLoading || isDatastoreLoading || isReaderLoading) &&
        (formMode === FormMode.NONE ? (
          <IngestersList
            setFormMode={setFormMode}
            setSelectedIngester={setSelectedIngester}
            loadingState={loadingState}
            setLoadingState={setLoadingState}
            handleTabChange={handleTabChange}
            sortedData={sortedData}
          />
        ) : (
          <>
            <span className={styles.tab_title}>
              {formMode === FormMode.CREATE
                ? "Create New Ingester"
                : `Edit "${selectedIngester?.name}" Ingester`}
            </span>
            <IngesterForm
              submitFun={
                formMode === FormMode.CREATE
                  ? handleCreateIngester
                  : handleUpdateIngester
              }
              handleTabChange={handleTabChange}
              mode={formMode}
              ingester={selectedIngester}
              isLoading={isCreateLoading || isUpdateLoading}
            />
          </>
        ))}
    </div>
  );
};

export default IngesterConfig;

export const sortObjectsByField = <T>(
  array: T[],
  field: keyof T,
  ascending: boolean = true
): T[] => {
  const lowerField = field.toString().toLowerCase() as keyof T; // Converti il nome del campo a minuscolo

  if (array?.length > 0) {
    return array?.slice().sort((a, b) => {
      const aValue = a[lowerField];
      const bValue = b[lowerField];

      if (aValue === null || aValue === undefined) return 1;
      if (bValue === null || bValue === undefined) return -1;

      let comparison = 0;

      if (typeof aValue === 'string' && typeof bValue === 'string') {
        // Confronto case-insensitive per stringhe
        comparison = aValue.toLowerCase().localeCompare(bValue.toLowerCase());
      } else if (aValue instanceof Date && bValue instanceof Date) {
        // Confronto per date
        comparison = aValue.getTime() - bValue.getTime();
      } else {
        // Confronto standard per altri tipi
        comparison = aValue > bValue ? 1 : aValue < bValue ? -1 : 0;
      }

      return ascending ? comparison : -comparison;
    });
  } else return array;
};


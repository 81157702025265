import { useCallback, useState } from "react";
import { Button } from "@headlessui/react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { useCreateReaderMutation, useGetReadersByTenantQuery, useUpdateReaderMutation } from "../../../api/readerApi";
import ReaderForm from "./ReaderForm";
import Spinner from "../../Spinner";
import { IReaderResponse, IReaderRequest, sortingKeys } from "../../../types/IReader";
import { FormMode } from "../../../configs/FormMode";
import ReadersList from "./ReadersList/ReadersList";
import { updateSettings } from "../../../slices/modalsSlice";
import { useHandleCreating, useHandleUpdateFeedback } from "../../../utils/settingsUtils";
import Sorting from "../../Sorting";
import { titleCase } from "../../../utils/titleCase";

import styles from "../Config.module.scss";
import { IError } from "../../../types/IErrors";
import { useTranslation } from "react-i18next";
import { hasDataProperty } from "../../../utils/checkError";


interface IReaderConfig {
  formMode: string;
  setFormMode: (mode: string) => void;
}

const ReaderConfig = ({ formMode, setFormMode }: IReaderConfig) => {
  const dispatch = useDispatch();
  const tenant_id = localStorage.getItem("tenant_id") ?? "";
  const { data } = useGetReadersByTenantQuery(tenant_id);
  const [sortedData, setSortedData] = useState<IReaderResponse[]>(data?.data ?? []);
  const [selectedReader, setSelectedReader] = useState<IReaderResponse>();
  const [loadingState, setLoadingState] = useState<{ loading: boolean; id: string | null; }>({ loading: false, id: null });
  const { isLoading } = useGetReadersByTenantQuery(tenant_id);
  const [createReader, { isLoading: isCreateLoading }] = useCreateReaderMutation();
  const [updateReader, { isError: isUpdateError, isSuccess: isUpdateSuccess, isLoading: isUpdateLoading }] = useUpdateReaderMutation();
  const { t } = useTranslation()


  const handleCreating = useHandleCreating({ formMode, setFormMode });//Modal Handler
  useHandleUpdateFeedback({
    isSuccess: isUpdateSuccess,
    isError: isUpdateError,
    successMessage: "Data Origin Updated",
    setLoadingState,
  }); //Object Update Feedback Handler

  const handleUpdateReader = useCallback(async (updatedReader: IReaderRequest) => {
    setLoadingState({ loading: true, id: updatedReader.id });
    try {
      await updateReader(updatedReader).unwrap()
      dispatch(
        updateSettings({
          isFull: false,
          modalName: "settings",
          tab: "reader",
        })
      );
      toast.success('Data Origin Updated');
      setFormMode(FormMode.NONE);
    } catch (error) {
      if (hasDataProperty(error)) {
        const typedError = error as IError;
        if (typedError.data.message) {
          toast.error(t(`errors.${typedError.data.code}`));
          toast.warning(`Details: ${typedError.data.message}`);
        } else {
          toast.error(t(`errors.${typedError.data.code}`));
        }
      } else {
        toast.error(`${t("errors.generic")}`);
      }
    }
    setLoadingState({ loading: false, id: updatedReader.id });

  }, [dispatch, setFormMode, t, updateReader]);

  const handleCreateReader = useCallback(async (newReader: IReaderRequest) => {
    setLoadingState({ loading: true, id: newReader.id });
    try {
      await createReader(newReader).unwrap();
      dispatch(updateSettings({ isFull: false, modalName: "settings", tab: "reader" }));
      toast.success('Data Origin Created');
      setFormMode(FormMode.NONE);
    } catch (error) {
      if (hasDataProperty(error)) {
        const typedError = error as IError;
        if (typedError.data.message) {
          toast.error(t(`errors.${typedError.data.code}`));
          toast.warning(`Details: ${typedError.data.message}`);
        } else {
          toast.error(t(`errors.${typedError.data.code}`));
        }
      } else {
        toast.error(`${t("errors.generic")}`);
      }
    }
    setLoadingState({ loading: false, id: newReader.id });

  }, [createReader, dispatch, setFormMode, t]);

  return (
    <div className={styles.container}>
      <div className={styles.header_container}>
        <Button className={styles.create_button} onClick={handleCreating}>
          {formMode === FormMode.CREATE || formMode === FormMode.EDIT ? (
            <div className={styles.arrow_wrapper}>
              <div className={styles.arrow}></div>
            </div>
          ) : (
            FormMode.CREATE
          )}
        </Button>
        {formMode === FormMode.NONE && (
          <Sorting
            data={data?.data}
            setSortedData={setSortedData}
            defaultSort={titleCase(sortingKeys[0])}
            options={sortingKeys}
          />
        )}
      </div>
      {isLoading && <Spinner />}
      {!isLoading &&
        (formMode === FormMode.NONE ? (
          <ReadersList
            setFormMode={setFormMode}
            setSelectedReader={setSelectedReader}
            loadingState={loadingState}
            setLoadingState={setLoadingState}
            sortedData={sortedData}
          />
        ) : (
          <>
            <span className={styles.tab_title}>
              {formMode === FormMode.CREATE
                ? "Create New Reader"
                : `Edit "${selectedReader?.name}" Reader`}
            </span>
            <ReaderForm
              submitFun={
                formMode === FormMode.CREATE
                  ? handleCreateReader
                  : handleUpdateReader
              }
              mode={formMode}
              reader={selectedReader}
              isLoading={isCreateLoading || isUpdateLoading}
            />
          </>
        ))}
    </div>
  );
};

export default ReaderConfig;

export const it = {
    signinPage: {
        welcome: "Benvenuto su",
        title: "Aitho Rag Enterprise",
        signin: "Accedi",
        signinDescription: "Benvenuto su Aitho Rag Enterprise.\nAccedi con le tue credenziali per provare.",
        buttonSignin: "Accedi",
    },
    dashboardPage: {

    },
    errors: {
        generic: "Ops! Qualcosa è andato storto.",
        "A-001": "Errore Agent Non Inizializzato",
        "A-002": "Errore nel nome dell'agente. Prova a cambiare il nome dell'agente.",
        "D-001": "Errore Eliminazione Datastore Con Oggetto Collegato",
        "I-001": "Errore Ingestion In Corso",
        "O-001": "Errore Creazione Oggetto",
        "O-002": "Errore Aggiornamento Oggetto",
        "O-003": "Errore Oggetto Non Trovato",
        "O-004": "Errore Connessione Oggetto",
        "R-001": "Errore Reader Non Inizializzato",
        "R-002": "Errore Eliminazione Ingester Con Oggetto Collegato",
        "R-003": "Errore Connessione Reader",
        "R-004": "Errore Validazione Reader",
        "R-005": "Errore Generico Reader",
    }
}
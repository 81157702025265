import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { toast } from "react-toastify";

import { EndpointConfig } from "../configs/EndpointConfig";
import { ITenant, ITenants } from "../types/ITenant";

const baseQuery = fetchBaseQuery({
  baseUrl: window._env_.REACT_APP_API_URL,
  prepareHeaders: (headers) => {
    headers.set("Content-Type", "application/json");
    const token = localStorage.getItem("access_token");

    if (token) {
      headers.set("Authorization", `${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    localStorage.clear();
    sessionStorage.clear();
    toast.warning("Your session has expired. Please log in again.");
  }
  return result;
};

export const tenantApi = createApi({
  reducerPath: "tenantApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getTenants: builder.query<ITenants, void>({
      // get all Agents Tenants
      query: () => {
        return {
          url: `${EndpointConfig.TENANT_URL}`,
          method: "GET",
        };
      },
    }),
    getTenant: builder.query<ITenants, string>({
      // get agent_tenant related to company_tenant
      query: (tenantId) => {
        return {
          url: `${EndpointConfig.TENANT_URL}/${tenantId}`,
          method: "GET",
        };
      },
    }),

    setWizardCompleted: builder.mutation<void, string>({
      query: (tenantId) => {
        return {
          url: `/${EndpointConfig.TENANT_URL}/${tenantId}/wizard_complete`,
          method: "POST",
        };
      },
    }),

     postTenant: builder.mutation<ITenants, ITenant>({
         query: (message) => ({
             url: `${EndpointConfig.TENANT_URL}` ,
             method: 'POST',
             body:  message ,
        }),
     }),
  }),
});

export const { useGetTenantsQuery, useLazyGetTenantQuery, useSetWizardCompletedMutation, useGetTenantQuery, usePostTenantMutation } = tenantApi;


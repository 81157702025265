import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { toast } from "react-toastify";

import { EndpointConfig } from "../configs/EndpointConfig";
import {
  IAgentData,
  IAgentQueryRequest,
  IAgentRequest,
  IAgentResponse,
  IAgentsResponse,
  IInitializeAgentRequest,
} from "../types/IAgents";
import { IAnswer } from "../types/IMessages";

const baseQuery = fetchBaseQuery({
  baseUrl: window._env_.REACT_APP_API_URL,
  prepareHeaders: (headers) => {
    headers.set("Content-Type", "application/json");
    const token = localStorage.getItem("access_token");

    if (token) {
      headers.set("Authorization", `${token}`);
    }
    return headers;
  },
});
const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    localStorage.clear();
    sessionStorage.clear();
    toast.warning("Your session has expired. Please log in again.");
  }
  return result;
};

export const agentApi = createApi({
  tagTypes: ["agentApi"],
  reducerPath: "agentApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getAgentByTenant: builder.query<IAgentsResponse, string>({
      // get Agents of specific agent_tenant
      query: (tenant_id) => {
        return {
          url: `/${EndpointConfig.AGENTS_URL}`,
          params: { tenant_id: tenant_id },
          method: "GET",
        };
      },
      transformResponse: (response: IAgentsResponse) => {
        const modifiedResponse: IAgentsResponse = {
          data: response.data.map((agent: IAgentResponse) => ({
            ...agent,
            conf: {
              llm_model: agent.conf.llm_model,
              search_type: agent.conf.search_type,
            },
          })),
        };
        return modifiedResponse;
      },
      providesTags: ["agentApi"],
    }),
    getAgentByAgent: builder.query<IAgentData, string>({
      // get Agents of specific agent_id
      query: (agentId) => {
        return {
          url: `/${EndpointConfig.AGENTS_URL}/${agentId}`,
          method: "GET",
        };
      },
      providesTags: ["agentApi"],
    }),
    initializeAgent: builder.query<IAgentsResponse, IInitializeAgentRequest>({
      // Initialize/Activate agent
      query: (params) => {
        return {
          url: `/${EndpointConfig.AGENTS_URL}/${params.agent_id}/initialize`,
          params: { tenant_id: params.tenant_id },
          method: "GET",
        };
      },
    }),
    terminateAgent: builder.query<IAgentsResponse, IInitializeAgentRequest>({
      // Terminate agent
      query: (params) => {
        return {
          url: `/${EndpointConfig.AGENTS_URL}/${params.agent_id}/terminate`,
          params: { tenant_id: params.tenant_id },
          method: "DELETE",
        };
      },
    }),
    createAgent: builder.mutation<IAgentResponse, IAgentRequest>({
      // Create Agent
      query: (params) => {
        const { agent_id, id, ...props } = params;
        return {
          url: `/${EndpointConfig.AGENTS_URL}`,
          method: "POST",
          body: props,
        };
      },
      invalidatesTags: ["agentApi"],
    }),
    updateAgent: builder.mutation<IAgentResponse, IAgentRequest>({
      query: (params) => {
        const { tenant_id, id, ...props } = params;
        return {
          url: `/${EndpointConfig.AGENTS_URL}/${props.agent_id}`,
          method: "PUT",
          body: props,
        };
      },
      invalidatesTags: ["agentApi"],
    }),
    deleteAgent: builder.mutation<string, string>({
      invalidatesTags: ["agentApi"],
      query: (agent_id) => ({
        url: `/${EndpointConfig.AGENTS_URL}/${agent_id}`,
        method: "DELETE",
      }),
    }),
    postMessage: builder.mutation<IAnswer, IAgentQueryRequest>({
      query: (params) => ({
        url: `/${EndpointConfig.AGENTS_URL}/${params.agent_id}/run`,
        method: "POST",
        params: {tenant_id: params.tenant_id},
        body: { query: params.query },
      }),
    }),
  }),
});

export const {
  useCreateAgentMutation,
  useUpdateAgentMutation,
  usePostMessageMutation,
  useDeleteAgentMutation,
  useGetAgentByAgentQuery,
  useGetAgentByTenantQuery,
  useLazyGetAgentByTenantQuery,
  useLazyInitializeAgentQuery,
  useInitializeAgentQuery,
  useLazyTerminateAgentQuery,
} = agentApi;

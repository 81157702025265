import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ModalsState {
  settings: ModalState;
  continue: ModalState;
  message: ModalState;
}
export interface ModalState {
  isFull?: boolean;
  modalName: string;
  isOpen: boolean;
  tab?: string;
  destinationTabIndex?:number;
  yesMethod?: string;
}

const initialState: ModalsState = {
  settings: {
    isFull: false,
    isOpen: false,
    modalName: "settings",
    tab: ""
  },
  continue: {
    isFull: false,
    isOpen: false,
    modalName: "continue",
    tab: ""
  },
  message: {
    isFull: false,
    isOpen: false,
    modalName: "message",
    destinationTabIndex:0,
    tab: "",
    yesMethod: "",
  }
};

export const modalsSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    updateSettings: (state, action: PayloadAction<Partial<ModalState>>) => {
      state.settings = { ...state.settings, ...action.payload };
    },
    updateContinue: (state, action: PayloadAction<Partial<ModalState>>) => {
      state.continue = { ...state.continue, ...action.payload };
    },
    updateMessage: (state, action: PayloadAction<ModalState>) => {
      state.message = { ...state.message, ...action.payload };
    },
    setIsOpenForSettings: (state, action: PayloadAction<boolean>) => {

      state.settings.isOpen = action.payload;
    },
    setIsOpenForContinue: (state, action: PayloadAction<boolean>) => {

      state.continue.isOpen = action.payload;
    },
    setIsOpenForMessage: (state, action: PayloadAction<boolean>) => {
      state.message.isOpen = action.payload;
    },
    setIsOpenForMessageFun: (state, action: PayloadAction<ModalState>) => {
      const { yesMethod, isOpen } = action.payload;
      state.message.isOpen = isOpen;
      state.message.yesMethod = yesMethod;
    },
    setIsOpen: (state, action: PayloadAction<ModalState>) => {
      const { modalName, isOpen } = action.payload;

      if (modalName in state) {
        state[modalName as keyof ModalsState].isOpen = isOpen;
      }
    },
    resetModalState: () => initialState,
  },
});

export const { 
  updateSettings, 
  updateContinue, 
  updateMessage, 
  setIsOpenForSettings, 
  setIsOpenForContinue, 
  setIsOpenForMessage, 
  setIsOpen, 
  setIsOpenForMessageFun,
  resetModalState } = modalsSlice.actions;
export default modalsSlice.reducer;

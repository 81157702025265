import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setIsLoading } from '../slices/utilsSlice';

const useLoading = (key: string, loading: boolean) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setIsLoading({ key, value: loading }));
  }, [key, loading, dispatch]);
};

export default useLoading;
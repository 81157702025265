export interface IAgentsResponse {
    data: IAgentResponse[];
}

export interface IAgentResponse extends IAgent {
    id: string;
    continue_model_name?: string;
    tenant_id: TenantCollectionId;
    datastore_id: DatastoreId;
    conf: GeminiResponseConf;
}

export interface IAgentRequest extends IAgent {
    id:string;
    agent_id?: string;
    tenant_id: string;
    datastore_id?: string;
}

export interface IAgent {
    name: string;
    type: string;
    conf: GeminiRequestConf;
}
export interface IAgentData {
    data: IAgentResponse;
}
export interface TenantCollectionId {
    id: string;
    collection: string;
}

export interface GeminiRequestConf { // Gemini Conf
    llm_model: string;
    search_type: string;
}
export interface GeminiResponseConf { // Gemini Conf
    llm_model: string;
    search_type: string;
    project_id?: string;
    location_id?: string;
    vendor_datastore_id?: string;
    engine_name?: string;
}

export interface IAgentQueryRequest {
    tenant_id: string;
    agent_id: string;
    query: string;
}

export interface IInitializeAgentRequest {
    tenant_id: string;
    agent_id: string;
    user_filter: string;
}
export interface DatastoreId {
    id: string;
    collection: string;
}

export const initialAgentState: IAgentResponse = {
    id: '',
    tenant_id:{
        collection:"",
        id:""
    },
    name: "",
    type: "",
    datastore_id: {
        collection:"",
        id:""
    },
    conf: {
        llm_model: "",
        search_type: "",
    }
}

export const sortingKeys: string[] = ["id", "name", "type", "datastore_id"]

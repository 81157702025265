import { useCallback, useEffect, useState } from "react";
import {
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
import {
  Button,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
} from "@headlessui/react";

import { IGoogleUserDetails, UserDetailsType } from "../../types/IUser";
import IngesterConfig from "../../components/SettingsModal/IngesterConfig/IngesterConfig";

import styles from "./WizardPage.module.scss";
import ReaderConfig from "../../components/SettingsModal/ReaderConfig/ReaderConfig";
import DatastoreConfig from "../../components/SettingsModal/DatastoreConfig/DatastoreConfig";
import AgentConfig from "../../components/SettingsModal/AgentConfig/AgentConfig";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  CheckIcon,
} from "@heroicons/react/24/solid";
import { useGetReadersByTenantQuery } from "../../api/readerApi";
import { useGetDatastoresByTenantQuery } from "../../api/datastoreApi";
import { useGetIngesterByTenantQuery } from "../../api/ingesterApi";
import { useGetAgentByTenantQuery } from "../../api/agentApi";
import { useLocation, useNavigate } from "react-router-dom";
import { RoutesEnum } from "../../configs/routesEnum";
import { ITab, initialTabs } from "../../types/ITabs";
import { FormMode } from "../../configs/FormMode";
import MessageModal from "../../components/MessageModal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import {
  ModalsState,
  setIsOpenForMessage,
  setIsOpenForSettings,
  updateMessage,
  updateSettings,
} from "../../slices/modalsSlice";
import { useGetTenantQuery, useSetWizardCompletedMutation } from "../../api/tenantApi";
interface IYesMethods {
  back: () => void;
  quit: () => void;
  tab: () => void;
}

const Wizard = () => {
  const isAuth = localStorage.getItem("isAuth");
  const authProvider = localStorage.getItem("authProvider");
  const vendor_id = localStorage.getItem('vendor_id') ?? '';

  const { accounts } = useMsal();

  const isAuthenticated = useIsAuthenticated();
  const [, setUserDetails] = useState<UserDetailsType | null>(null);
  const [userName, setUserName] = useState<string>("");
  const [formMode, setFormMode] = useState(FormMode.NONE);

  const [tabs, setTabs] = useState<ITab[]>(initialTabs);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [isAllCompleted, setIsAllCompleted] = useState<boolean>(false);

  const tenant_id = localStorage.getItem("tenant_id") ?? "";
  const modals = useSelector((state: RootState) => state.modals);
  const dispatch = useDispatch();

  const { data: tenant} = useGetTenantQuery(vendor_id);
  const { data: readers } = useGetReadersByTenantQuery(tenant_id);
  const { data: datastores } = useGetDatastoresByTenantQuery(tenant_id);
  const { data: ingesters } = useGetIngesterByTenantQuery(tenant_id);
  const { data: agents } = useGetAgentByTenantQuery(tenant_id);
  const [setWizardCompleted] =
  useSetWizardCompletedMutation();

  const navigate = useNavigate();
  const location = useLocation();

  const messageModalsYesMethods: IYesMethods = {
    quit: () => {
      dispatch(setIsOpenForMessage(false));
      dispatch(setIsOpenForSettings(false));
    },
    back: () => {
      dispatch(setIsOpenForMessage(false));
      dispatch(updateSettings({ isFull: false, isOpen: true }));
      setFormMode(FormMode.NONE);
    },
    tab: () => {
      dispatch(setIsOpenForMessage(false));
      dispatch(updateSettings({ isFull: false, isOpen: true }));
      setFormMode(FormMode.NONE);
      handleUrlTabChange(modals.message.destinationTabIndex ?? 0);
    },
  };

  const handleUrlTabChange = useCallback(
    (index: number) => {
      setFormMode(FormMode.NONE);

      if (index < 0) {
        setSelectedIndex(0);
        const tab = tabs.find((tab) => tab.id === 0);
        const tabName = tab ? tab.name : undefined;
        if (tabName) {
          window.history.pushState({}, "", `${location.pathname}/${tabName}`);
        }
      } else if (index > 3) {
        setSelectedIndex(3);
        const tab = tabs.find((tab) => tab.id === 3);
        const tabName = tab ? tab.name : undefined;
        if (tabName) {
          window.history.pushState({}, "", `${location.pathname}/${tabName}`);
        }
      } else {
        setSelectedIndex(index);
        const tab = tabs.find((tab) => tab.id === index);
        const tabName = tab ? tab.name : undefined;
        if (tabName) {
          window.history.pushState({}, "", `${tabName}`);
        }
      }
    },
    [location.pathname, tabs]
  );

  const handleTabChange = useCallback(
    (index: number) => {
      if (
        modals["settings" as keyof ModalsState].isFull &&
        modals["settings" as keyof ModalsState].isOpen
      ) {
        dispatch(
          updateMessage({
            isOpen: true,
            modalName: "message",
            destinationTabIndex: index,
            yesMethod: "tab",
          })
        );
      } else {
        handleUrlTabChange(index);
      }
    },
    [dispatch, handleUrlTabChange, modals]
  );

  const goToDashboard = useCallback(async() => {
    if ((isAuthenticated || isAuth) && isAllCompleted) {
      await setWizardCompleted(vendor_id);
      dispatch(setIsOpenForSettings(false));
      navigate(RoutesEnum.DASHBOARD);
    }
  }, [vendor_id, dispatch, isAllCompleted, isAuth, isAuthenticated, navigate, setWizardCompleted]);

  useEffect(() => {
    if (authProvider === "google") {
      const userInfoString = localStorage.getItem("userInfo");
      if (userInfoString) {
        const googleUserInfo = JSON.parse(userInfoString) as IGoogleUserDetails;
        setUserDetails(googleUserInfo);
        setUserName(googleUserInfo.name ?? "");
        dispatch(setIsOpenForSettings(true));
      }
    } else if (authProvider === "microsoft") {
      setUserDetails(accounts);
      setUserName(accounts[0].name ?? "");
      dispatch(setIsOpenForSettings(true));
    } else {
      setUserDetails(null); // Ensure state is reset
      setUserName("");
    }
  }, [authProvider, accounts, dispatch]);

  const handleUrl = useCallback(() => {
    const lastSegment =
      window.location.href.split("/").filter(Boolean).pop() ?? "";
    const indexOfTabUrl = tabs.findIndex(
      (tab: ITab) => tab.name === lastSegment
    );

    if (
      indexOfTabUrl !== -1 &&
      tabs.every((tab: ITab) => tab.isSetted) &&
      tabs[indexOfTabUrl].completed
    ) {
      if (window.location.href.endsWith("/")) {
        window.history.replaceState({}, "", window.location.href.slice(0, -1));
      }
      setSelectedIndex(indexOfTabUrl);
    } else if (tabs.every((tab: ITab) => tab.isSetted)) {
      const firstUncompletedKey =
        tabs.find((tab: ITab) => !tab.completed) ?? tabs[tabs.length - 1];
      setSelectedIndex(firstUncompletedKey?.id ?? tabs.length - 1);
      if (lastSegment === "wizard") {
        if (window.location.href.endsWith("/")) {
          window.history.pushState(
            {},
            "",
            `${location.pathname}${firstUncompletedKey?.name}`
          );
        } else {
          window.history.pushState(
            {},
            "",
            `${location.pathname}/${firstUncompletedKey?.name}`
          );
        }
      } else {
        location.pathname = "/wizard";
        window.history.pushState(
          {},
          "",
          `${location.pathname}/${firstUncompletedKey?.name}`
        );
      }
    }
  }, [tabs, location]);

  useEffect(() => {
    handleUrl();
  }, [tabs, handleUrl]);

  useEffect(() => {
    readers?.data &&
      setTabs((prevTabs: ITab[]) => {
        return prevTabs.map((tab) => {
          if (tab.name === "readers") {
            return {
              ...tab,
              completed: (readers?.data?.length ?? 0) > 0,
              isSetted: true,
            };
          }
          return tab;
        });
      });

    datastores?.data &&
      setTabs((prevTabs: ITab[]) => {
        return prevTabs.map((tab) => {
          if (tab.name === "datastores") {
            return {
              ...tab,
              completed: (datastores?.data?.length ?? 0) > 0,
              isSetted: true,
            };
          }
          return tab;
        });
      });

    ingesters?.data &&
      setTabs((prevTabs: ITab[]) => {
        return prevTabs.map((tab) => {
          if (tab.name === "ingesters") {
            return {
              ...tab,
              completed: (ingesters?.data?.length ?? 0) > 0,
              isSetted: true,
            };
          }
          return tab;
        });
      });
    agents?.data &&
      setTabs((prevTabs: ITab[]) => {
        return prevTabs.map((tab) => {
          if (tab.name === "agents") {
            return {
              ...tab,
              completed: (agents?.data?.length ?? 0) > 0,
              isSetted: true,
            };
          }
          return tab;
        });
      });

    if (
      (readers?.data?.length ?? 0) > 0 &&
      (datastores?.data?.length ?? 0) > 0 &&
      (ingesters?.data?.length ?? 0) > 0 &&
      (agents?.data?.length ?? 0) > 0
    ) {
      setIsAllCompleted(true);
    } else if (isAllCompleted) {
      setIsAllCompleted(false);
    }
  }, [readers, datastores, ingesters, agents, isAllCompleted]);

  useEffect(() => {
    tenant?.data?.is_wizard_complete && navigate(RoutesEnum.DASHBOARD)
  }, [navigate, tenant]);

  return (
    <div className={styles.wizard_container}>
      {isAuth || isAuthenticated ? (
        <>
          {modals.message.isOpen && (
            <div className={styles.overlay}>
              <MessageModal
                isOpen={modals.message.isOpen}
                setIsOpen={(state) => dispatch(setIsOpenForMessage(state))}
                message="Unsaved changes detected. Any unsaved changes will be lost. Do you want to proceed?"
                title="Warning!"
                options={[
                  {
                    method: modals.message.yesMethod
                      ? messageModalsYesMethods[
                          modals.message.yesMethod as keyof IYesMethods
                        ]
                      : () => {},
                    text: "Yes",
                  },
                  {
                    method: () => dispatch(setIsOpenForMessage(false)),
                    text: "No",
                  },
                ]}
              />
            </div>
          )}
          <div className={styles.title}>
            <h1 className={styles.wizardWelcome}>Welcome {userName}!</h1>
            <h2 className={styles.wizardDesc}>
              Complete the wizard to use the app
            </h2>
          </div>
          <TabGroup
            className={styles.wizard_content}
            selectedIndex={selectedIndex}
            onChange={(index) => handleTabChange(index)}
          >
            <TabList className={styles.sidebar_progress}>
              {tabs.map((tab: ITab) => (
                <>
                  <Tab
                    key={tab.id}
                    className={`${styles.progress_item} ${
                      tab.completed && styles.completed
                    }`}
                    disabled={!tabs[tab.id - 1]?.completed && tab.id > 0}
                  >
                    <div className={styles.circle}>
                      {tab.completed && (
                        <CheckIcon className={styles.check_Icon} />
                      )}
                    </div>
                    <p className={styles.progress_title}>{tab?.label}</p>
                  </Tab>

                  {tabs.length - 1 !== tab.id && (
                    <span
                      className={`${styles.link_line} ${
                        tab.completed && styles.completed
                      }`}
                    ></span>
                  )}
                </>
              ))}
            </TabList>
            <TabPanels className={styles.tab_panels}>
              <TabPanel className={styles.tab_panel}>
                <ReaderConfig
                  formMode={formMode}
                  setFormMode={(state: any) => setFormMode(state)}
                />{" "}
              </TabPanel>
              <TabPanel className={styles.tab_panel}>
                <DatastoreConfig
                  formMode={formMode}
                  setFormMode={(state: any) => setFormMode(state)}
                />{" "}
              </TabPanel>
              <TabPanel className={styles.tab_panel}>
                <IngesterConfig
                  handleTabChange={handleTabChange}
                  formMode={formMode}
                  setFormMode={(state: any) => setFormMode(state)}
                />{" "}
              </TabPanel>
              <TabPanel className={styles.tab_panel}>
                <AgentConfig
                  handleTabChange={handleTabChange}
                  formMode={formMode}
                  setFormMode={(state: any) => setFormMode(state)}
                />{" "}
              </TabPanel>
            </TabPanels>
          </TabGroup>
          <div className={styles.steps_button}>
            <Button
              className={styles.button}
              onClick={() => handleTabChange(selectedIndex - 1)}
              disabled={selectedIndex === 0}
            >
              <div className={styles.button_icon}>
                <ChevronLeftIcon />
              </div>
              <p className={styles.button_text}>Previous</p>
            </Button>
            {selectedIndex === 3 ? (
              <Button
                className={styles.button}
                onClick={goToDashboard}
                disabled={!isAllCompleted}
              >
                <p className={styles.button_text}>Dashboard</p>
                <div className={styles.button_icon}>
                  <ChevronRightIcon />
                </div>
              </Button>
            ) : (
              <Button
                className={styles.button}
                onClick={() => handleTabChange(selectedIndex + 1)}
                disabled={!tabs[selectedIndex].completed}
              >
                <p className={styles.button_text}>Next</p>
                <div className={styles.button_icon}>
                  <ChevronRightIcon />
                </div>
              </Button>
            )}
          </div>
        </>
      ) : (
        <UnauthenticatedTemplate></UnauthenticatedTemplate>
      )}
    </div>
  );
};

export default Wizard;

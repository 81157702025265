import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { toast } from "react-toastify";

import { EndpointConfig } from "../configs/EndpointConfig";
import {
  IDatastoreObject,
  IDatastoreRequest,
  IDatastores,
} from "../types/IDatastore";

const baseQuery = fetchBaseQuery({
  baseUrl: window._env_.REACT_APP_API_URL,
  prepareHeaders: (headers) => {
    headers.set("Content-Type", "application/json");
    const token = localStorage.getItem("access_token");

    if (token) {
      headers.set("Authorization", `${token}`);
    }

    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    localStorage.clear();
    sessionStorage.clear();
    toast.warning("Your session has expired. Please log in again.");
  }
  return result;
};

export const datastoreApi = createApi({
  tagTypes: ["datastoreApi"],
  reducerPath: "datastoreApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getDataStore: builder.query<IDatastoreObject, string>({
      query: (datastore_id) => ({
        url: `/${EndpointConfig.DATASTORE_URL}/${datastore_id}`,
        method: "GET",
      }),
      transformResponse: (response: IDatastoreObject) => {
        const modifiedResponse = {
          ...response,
          conf: {
            project_id: response.data.conf.project_id,
            location_id: response.data.conf.location_id,
            data_store_name: response.data.conf.data_store_name,
          },
        };
        return modifiedResponse;
      },
      providesTags: ["datastoreApi"],
    }),
    getDatastoresByTenant: builder.query<IDatastores, string>({
      query: (tenant_id) => ({
        url: `/${EndpointConfig.DATASTORE_URL}`,
        params: {tenant_id: tenant_id},
        method: "GET",
      }),
      transformResponse: (response: IDatastores) => {
        const modifiedResponse = {
          ...response,
          data: response.data.map((datastore) => ({
            ...datastore,
            conf: {
              project_id: datastore.conf.project_id,
              location_id: datastore.conf.location_id,
              data_store_name: datastore.conf.data_store_name,
            },
          })),
        };
        return modifiedResponse;
      },
      providesTags: ["datastoreApi"],
    }),
    createDatastore: builder.mutation<string, IDatastoreRequest>({
      // create datastore
      query: (params) => {
        const { id, ...props } = params;
        return {
          url: `/${EndpointConfig.DATASTORE_URL}`,
          method: "POST",
          body: props,
        };
      },
      invalidatesTags: ["datastoreApi"],
    }),
    updateDatastore: builder.mutation<string, IDatastoreRequest>({
      query: (params) => {
        const { tenant_id, id, ...props } = params;
        return {
          url: `/${EndpointConfig.DATASTORE_URL}/${params.id}`,
          method: "PUT",
          body: props,
        };
      },
      invalidatesTags: ["datastoreApi"],
    }),
    deleteDatastore: builder.mutation<string, string>({
      query: (datastore_id) => ({
        url: `/${EndpointConfig.DATASTORE_URL}/${datastore_id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["datastoreApi"],
    }),
  }),
});

export const {
  useGetDataStoreQuery,
  useGetDatastoresByTenantQuery,
  useCreateDatastoreMutation,
  useUpdateDatastoreMutation,
  useDeleteDatastoreMutation,
} = datastoreApi;

import { useCallback, useEffect, useState } from "react";
import {
  Field,
  Fieldset,
  Input,
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Textarea,
} from "@headlessui/react";
import { useDispatch } from "react-redux";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { ValidationError } from "yup";
import { toast } from "react-toastify";

import {IIngesterRequest,IIngesterResponse,initialIngesterState } from "../../../../types/IIngester";
import { useGetReadersByTenantQuery } from "../../../../api/readerApi";
import { useGetDatastoresByTenantQuery } from "../../../../api/datastoreApi";
import { FormMode } from "../../../../configs/FormMode";
import { updateSettings } from "../../../../slices/modalsSlice";
import Spinner from "../../../Spinner";
import { ingesterFormSchema } from "../../schema";

import styles from "../../Form.module.scss";


interface IIngesterForm {
  submitFun: (form: IIngesterRequest) => void;
  handleTabChange: (index: number) => void;
  mode: string;
  ingester?: IIngesterResponse;
  isLoading: boolean;
}

const IngesterForm = ({submitFun, handleTabChange, mode, ingester, isLoading}: IIngesterForm) => {
  const tenant_id = localStorage.getItem("tenant_id") ?? "";
  const dispatch = useDispatch();

  const [ingesterForm, setIngesterForm] = useState<IIngesterResponse>(
    mode === FormMode.EDIT && ingester ? ingester : initialIngesterState
  );

  const { data: dataOrigin } = useGetReadersByTenantQuery(tenant_id);
  const { data: dataStore } = useGetDatastoresByTenantQuery(tenant_id);

  const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = e.target;
      setIngesterForm((prevForm) => ({
        ...prevForm,
        [name]: value,
      }));
    },
    []
  );

  const handleSelectChange = useCallback((name: "reader_id" | "datastore_id", value: string) => {
    if (name === "datastore_id" && value === "create_new_datastore")
      handleTabChange(2);

    if (name === "reader_id" && value === "create_new_data_origin")
      handleTabChange(1);

    setIngesterForm((prevForm) => ({
      ...prevForm,
      [name]: {
        ...prevForm[name],
        id: value,
      },
    }));
  },[handleTabChange]);

  const handleSubmit = useCallback(async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      await ingesterFormSchema.validate(ingesterForm, { abortEarly: false });


      const finalForm: IIngesterRequest = {
        name: ingesterForm.name,
        description: ingesterForm.description,
        cronjob: ingesterForm.cronjob,
        tenant_id: tenant_id,
        reader_id: ingesterForm.reader_id.id,
        datastore_id: ingesterForm.datastore_id.id,
        ingester_id: ingesterForm.id,
      };

      submitFun(finalForm);
    } catch (err) {
      if (err instanceof ValidationError) {
        err.inner.forEach((error) => {
          if (error.message) {
            toast.warning(error.message);
          }
        });
      } else {
        toast.warning("Invalid JSON format");
      }
    }
  },[tenant_id, ingesterForm, submitFun]);

  useEffect(() => {
    const hasNonEmptyForm =
      mode === FormMode.CREATE
        ? JSON.stringify(ingesterForm) !== JSON.stringify(initialIngesterState)
        : JSON.stringify(ingesterForm) !== JSON.stringify(ingester);

    if (hasNonEmptyForm) {
      dispatch(
        updateSettings({
          isFull: true,
          modalName: "settings",
          tab: "ingesters",
        })
      );
    } else {
      dispatch(
        updateSettings({
          isFull: false,
          modalName: "settings",
          tab: "ingesters",
        })
      );
    }
  }, [dispatch, ingester, ingesterForm, mode]);

  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      <Fieldset as="div" className={styles.fields}>
        <Field as="div" className={styles.field}>
          <Label className={styles.label}>Name: </Label>
          <Input
            type="text"
            required
            name="name"
            placeholder="Ingester name"
            value={ingesterForm.name}
            onChange={handleInputChange}
            className={styles.input}
            disabled={isLoading}
          />
        </Field>

        <Field as="div" className={styles.field}>
          <Label className={styles.label}>Description: </Label>
          <Textarea
            name="description"
            placeholder="Ingester description"
            value={ingesterForm.description}
            onChange={handleInputChange}
            className={styles.text_area}
            rows={3}
            disabled={isLoading}
          />
        </Field>

        <Field as="div" className={styles.field}>
          <Label className={styles.label}>Data Origin: </Label>
          <Listbox
            value={ingesterForm.reader_id.id}
            onChange={(value: string) => handleSelectChange("reader_id", value)}
            as="div"
            className={styles.list_box}
          >
            <ListboxButton className={styles.list_button}>
              <span className={styles.agent_name}>
                {dataOrigin?.data.find(
                  (reader) => reader.id === ingesterForm.reader_id.id
                )?.name ?? "Select a Data Origin"}
              </span>
              <ChevronDownIcon className={styles.list_icon} />
            </ListboxButton>
            <ListboxOptions
              anchor="bottom start"
              className={styles.list_options}
            >
              {dataOrigin?.data && dataOrigin?.data.length > 0 ? (
                dataOrigin?.data.map((reader) => (
                  <ListboxOption
                    key={reader.id}
                    value={reader.id}
                    className={styles.list_option}
                    disabled={isLoading}
                  >
                    {reader.name}
                  </ListboxOption>
                ))
              ) : (
                <ListboxOption
                  key={crypto.randomUUID()}
                  value="create_new_data_origin"
                  className={styles.list_option}
                >
                  <span>Create new datastore...</span>
                </ListboxOption>
              )}
            </ListboxOptions>
          </Listbox>
        </Field>

        <Field as="div" className={styles.field}>
          <Label className={styles.label}>Datastore: </Label>
          <Listbox
            value={ingesterForm.datastore_id.id}
            onChange={(value: string) =>
              handleSelectChange("datastore_id", value)
            }
            as="div"
            className={styles.list_box}
          >
            <ListboxButton className={styles.list_button}>
              <span className={styles.agent_name}>
                {dataStore?.data.find(
                  (datastore) => datastore.id === ingesterForm.datastore_id.id
                )?.name ?? "Select a datastore"}
              </span>
              <ChevronDownIcon className={styles.list_icon} />
            </ListboxButton>
            <ListboxOptions
              anchor="bottom start"
              className={styles.list_options}
            >
              {dataStore?.data && dataStore?.data.length > 0 ? (
                dataStore?.data.map((datastore) => (
                  <ListboxOption
                    key={datastore.id}
                    value={datastore.id}
                    className={styles.list_option}
                    disabled={isLoading}
                  >
                    {datastore.name}
                  </ListboxOption>
                ))
              ) : (
                <ListboxOption
                  key={crypto.randomUUID()}
                  value="create_new_datastore"
                  className={styles.list_option}
                >
                  <span>Create new datastore...</span>
                </ListboxOption>
              )}
            </ListboxOptions>
          </Listbox>
        </Field>

        <Field as="div" className={styles.field}>
          {isLoading ? <Spinner /> :
          <button type="submit" className={styles.submit_button}>
            Submit
          </button>}
        </Field>
      </Fieldset>
    </form>
  );
};

export default IngesterForm;

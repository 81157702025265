import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IUser {
    id: string;
    name: string;
    username: string;
}

const initialUserState: IUser = {
    id: "",
    name: "",
    username: "",
}

export const userSlice = createSlice({
    name: "user",
    initialState: initialUserState,
    reducers: {
        setCurrentUser: (state, action: PayloadAction<IUser>) => {
            return state = { ...state, ...action.payload };
        },
    }
});
export const { setCurrentUser } = userSlice.actions;
export default userSlice.reducer;
import { useEffect, useRef } from "react";
import { IMessage } from "../../../types/IMessages";
import ReactMarkdown from "react-markdown";
import styles from "./Messages.module.scss"
import Spinner from "../../Spinner/Spinner";
import CodeBlockRenderer from "../CodeBlockRenderer/CodeBlockRenderer";
import DocDetails from "../../DocDetails/DocDetails";
export interface IMessages {
  messages: IMessage[];
  loading: boolean;
}

const Messages = ({ messages, loading }: IMessages) => {
  const el = useRef<null | HTMLDivElement>(null);
  useEffect(() => {
    if (el.current)
      el.current.scrollIntoView({ block: "end", behavior: "smooth" });
  });

  return (
    <div className={styles.messages_container}>
      {messages.map((message) => (
        <div
          key={message.messageId}
          className={`${styles.message} ${
            message.user === "bot" ? styles.bot_message : styles.user_message
          }`}
        >
          {
            <ReactMarkdown
              children={message.message}
              components={{
                code: CodeBlockRenderer as React.ElementType,
              }}
            />
          }
          {(message.user === "bot" && message.docs &&  message.docs?.length > 0) && <DocDetails docs={message.docs}/>}
        </div>
      ))}
      {loading && (
        <div className={styles.spinner_container}>
          <Spinner />
        </div>
      )}
      <div ref={el} />
    </div>
  );
};

export default Messages;


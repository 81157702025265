import { useCallback, useState, useEffect } from "react";
import {
  Field,
  Fieldset,
  Input,
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Textarea,
} from "@headlessui/react";
import { useDispatch } from "react-redux";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { toast } from "react-toastify";
import * as Yup from "yup";

import {
  IDatastore,
  IDatastoreRequest,
  IGeminiConfResponse,
  initialDatastoreState,
} from "../../../../types/IDatastore";
import { updateSettings } from "../../../../slices/modalsSlice";
import { FormMode } from "../../../../configs/FormMode";
import { datastore_type } from "../../../../configs/DatastoreConfig";
import Spinner from "../../../Spinner";
import { datastoreFormSchema, geminiConfSchema } from "../../schema";

import styles from "../../Form.module.scss";


interface IDatastoreForm {
  submitFun: (form: IDatastoreRequest) => void;
  mode: string;
  datastore?: IDatastore;
  isLoading: boolean;
}

const DatastoreForm = ({submitFun, mode,datastore, isLoading }: IDatastoreForm) => {
  const dispatch = useDispatch();
  const tenant_id = localStorage.getItem("tenant_id") ?? "";

  const [datastoreForm, setDatastoreForm] = useState<IDatastore>( mode === FormMode.EDIT && datastore ? datastore : initialDatastoreState);
  const [datastoreConf, setDatastoreConf] = useState<IGeminiConfResponse>( mode === FormMode.EDIT && datastore
      ? datastore.conf
      : { location_id: "eu", //Al momento vogliamo che location_id sia fissa a "eu"
        project_id: "",
        data_store_name: "" }
  );

  const handleTextAreaChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const { name, value } = e.target;
      setDatastoreForm((prevForm) => ({ ...prevForm, [name]: value }));
    },[]
  );

  const handelInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      setDatastoreForm((prevForm) => ({ ...prevForm, [name]: value }));
    },[]
  );

  const handleSelectChange = useCallback((name: string, value: string) => {
    setDatastoreForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  }, []);

  const handleSubmit = useCallback(async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      await datastoreFormSchema.validate(datastoreForm, { abortEarly: false });

      if (datastoreForm.conf) {
        await geminiConfSchema.validate(datastoreConf, { abortEarly: false });
      }

      const finalForm: IDatastoreRequest = {
        ...datastoreForm,
        conf: datastoreConf,
        tenant_id: tenant_id,
      };

      submitFun(finalForm);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          if (error.message) {
            toast.warning(error.message);
          }
        });
      } else {
        toast.warning("Invalid JSON format");
      }
    }
  }, [tenant_id, datastoreConf, datastoreForm, submitFun]);

  const handelInputConfigChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      setDatastoreConf((prev) => ({ ...prev, [name]: value }));
    },
    []
  );

  useEffect(() => {
    const hasNonEmptyForm =
      mode === FormMode.CREATE
        ? JSON.stringify(datastoreForm) !==
          JSON.stringify(initialDatastoreState)
        : JSON.stringify(datastoreForm) !== JSON.stringify(datastore);

    const hasNonEmptyVertexConf =
      mode === FormMode.EDIT && datastoreForm.datastore_type === "vertexai"
        ? JSON.stringify(datastoreConf) !== JSON.stringify(datastore?.conf)
        : Object.values(datastoreConf).some((value) => value !== "");

    if (hasNonEmptyForm || hasNonEmptyVertexConf) {
      dispatch(
        updateSettings({
          isFull: true,
          modalName: "settings",
          tab: "reader",
        })
      );
    } else {
      dispatch(
        updateSettings({
          isFull: false,
          modalName: "settings",
          tab: "reader",
        })
      );
    }
  }, [datastoreForm, datastoreConf, mode, datastore, dispatch]);
  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      <Fieldset as="div" className={styles.fields}>
        <Field as="div" className={styles.field}>
          <Label className={styles.label}>Name</Label>
          <Input
            className={styles.input}
            minLength={1}
            maxLength={50}
            required
            name="name"
            placeholder="DataStore name"
            onChange={handelInputChange}
            value={datastoreForm.name}
            disabled={isLoading}
          />
        </Field>
        <Field as="div" className={styles.field}>
          <Label className={styles.label}>Description</Label>
          <Textarea
            name="description"
            placeholder="DataStore description"
            value={datastoreForm.description}
            onChange={handleTextAreaChange}
            className={styles.text_area}
            minLength={1}
            maxLength={1000}
            rows={2}
            disabled={isLoading}
          />
        </Field>

        <Field as="div" className={styles.field}>
          <Label className={styles.label}>Datastore Type: </Label>
          <Listbox
            value={datastoreForm.datastore_type}
            onChange={(value: string) =>
              handleSelectChange("datastore_type", value)
            }
            as="div"
            className={styles.list_box}
          >
            <ListboxButton className={styles.list_button}>
              <span className={styles.agent_name}>
                {datastoreForm.datastore_type || "Select a datastore type"}
              </span>
              <ChevronDownIcon className={styles.list_icon} />
            </ListboxButton>
            <ListboxOptions
              anchor="bottom start"
              className={styles.list_options}
            >
              {datastore_type.map((dataType) => (
                <ListboxOption
                  key={crypto.randomUUID()}
                  value={dataType}
                  className={styles.list_option}
                  disabled={isLoading}
                >
                  {dataType}
                </ListboxOption>
              ))}
            </ListboxOptions>
          </Listbox>
        </Field>

        <div className={styles.jsonForm}>
          <p className={styles.jsonFormTitle}>Data Store Config:</p>
          <div className={styles.jsonFields}>
            {datastoreForm.datastore_type === "vertexai" ? (
              <>
                <Field as="div" className={styles.field}>
                  <Label className={styles.label}>Project ID:</Label>
                  <Input
                    className={styles.input}
                    required
                    minLength={1}
                    maxLength={100}
                    name="project_id"
                    placeholder='e.g. "decoded-academy-xxxxxx"'
                    onChange={handelInputConfigChange}
                    value={datastoreConf.project_id}
                    disabled={isLoading}
                  />
                </Field>{" "}
                <Field as="div" className={styles.field}>
                  <Label className={styles.label}>Location ID:</Label>
                  <Input
                    required
                    className={styles.input}
                    minLength={1}
                    maxLength={30}
                    name="location_id"
                    placeholder='e.g. "eu"'
                    // onChange={handelInputConfigChange} Al momento vogliamo che sia fissa a "eu"
                    value={datastoreConf.location_id}
                    disabled={isLoading}
                  />
                </Field>
              </>
            ) : (
              <p>Select a Datastore type first!</p>
            )}
          </div>
        </div>

        <Field as="div" className={styles.field}>
          {isLoading ? <Spinner /> :
          <button type="submit" className={styles.submit_button}>
            Submit
          </button>}
        </Field>
      </Fieldset>
    </form>
  );
};

export default DatastoreForm;

import { useState, useCallback, useEffect } from "react";
import { UnauthenticatedTemplate, useIsAuthenticated } from "@azure/msal-react";
import { Button } from "@headlessui/react";
import { Bars3Icon } from "@heroicons/react/24/solid";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { useLazyGetAgentByTenantQuery } from "../../api/agentApi";
import { Sidebar, Chatbot, SettingsModal } from "../../components";
import { IAgentsResponse } from "../../types/IAgents";
import useLoading from "../../hooks/useLoading";
import ContinueModal from "../../components/ContinueModal";
import { RootState, useAppDispatch } from "../../store/store";
import { agents } from "../../slices/agentsSlice";
import {
  ModalsState,
  setIsOpen,
  setIsOpenForContinue,
  setIsOpenForMessageFun,
} from "../../slices/modalsSlice";
import { IError } from "../../types/IErrors";
import { hasDataProperty } from "../../utils/checkError";

import styles from "./DashboardPage.module.scss";


const DashboardPage = () => {
  const [isSideBarOpen, setIsSideBarOpen] = useState<boolean>(true);

  const isAuth = localStorage.getItem('isAuth');
  const tenat_id = localStorage.getItem('tenant_id') ?? ''

  const dispatch = useAppDispatch();
  const modals = useSelector((state: RootState) => state.modals);

  const [getAgentByTenant, { isLoading: isAgentLoading }] = useLazyGetAgentByTenantQuery();
  const isAuthenticated = useIsAuthenticated();
  const { t } = useTranslation()

  useLoading("agent", isAgentLoading);

  const tenantHandler = useCallback(async () => {
        try {
          const agentsResponse: IAgentsResponse = await getAgentByTenant(tenat_id).unwrap();
          dispatch(agents(agentsResponse));
        } catch (error) {
          if (hasDataProperty(error)) {
            const typedError = error as IError;
            if (typedError.data.message) {
              toast.error(t(`errors.${typedError.data.code}`));
              toast.warning(`Details: ${typedError.data.message}`);
            } else {
              toast.error(t(`errors.${typedError.data.code}`));
            }
          } else {
            toast.error(`${t("errors.generic")}`);
          }
        }
      },[dispatch, getAgentByTenant, t, tenat_id]);


  useEffect(() => {
    tenantHandler();
  }, [tenantHandler])


  const handleModalToggle = useCallback(
    (modalName: string) => {
      if (
        modals[modalName as keyof ModalsState].isFull &&
        modals[modalName as keyof ModalsState].isOpen
      ) {
        dispatch(
          setIsOpenForMessageFun({
            isOpen: true,
            modalName: "message",
            yesMethod: "quit",
          })
        );
      } else if (
        !modals[modalName as keyof ModalsState].isFull &&
        modals[modalName as keyof ModalsState].isOpen
      ) {
        dispatch(setIsOpen({ isOpen: false, modalName: modalName }));
      } else if (!modals[modalName as keyof ModalsState].isOpen) {
        dispatch(setIsOpen({ isOpen: true, modalName: modalName }));
      }
    },
    [dispatch, modals]
  );

  return (
    <div className={styles.dashboard_container}>
      {isAuth || isAuthenticated ? (
        <>
          <div
            className={`${styles.burger_icon_container} ${isSideBarOpen && styles.hide
              }`}
          >
            <Button
              className={styles.burger_icon}
              onClick={() => setIsSideBarOpen(!isSideBarOpen)}
            >
              <Bars3Icon className={styles.burger_icon} />
            </Button>
          </div>
          <div className={styles.content_container}>
            <Sidebar
              isOpen={isSideBarOpen}
              closeSideBar={() => setIsSideBarOpen(false)}
              handleSettings={handleModalToggle}
            />
            <Chatbot isSideBarOpen={isSideBarOpen} />
            {modals.settings.isOpen && (
              <div className={styles.overlay}>
                <SettingsModal
                  isOpen={modals.settings.isOpen}
                  setIsOpen={(state) => handleModalToggle("settings")}
                />
              </div>
            )}
            {modals.continue.isOpen && (
              <div className={styles.overlay}>
                <ContinueModal
                  isOpen={modals.continue.isOpen}
                  setIsOpen={(state) => dispatch(setIsOpenForContinue(state))}
                />
              </div>
            )}
          </div>
        </>
      ) : (
        <UnauthenticatedTemplate></UnauthenticatedTemplate>
      )}
    </div>
  );
};

export default DashboardPage;


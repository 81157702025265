import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";

import { RootState } from "../../store/store";
import { useGetAgentByAgentQuery } from "../../api/agentApi";
import { IAgentData } from "../../types/IAgents";
import { EndpointConfig } from "../../configs/EndpointConfig";
import Spinner from "../Spinner";
import CopyToClipboardButton from "../../utils/CopyToClipboard";

import styles from "./ContinueModal.module.scss";
interface IContinueModal {
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
}

const ContinueModal = ({ isOpen, setIsOpen }: IContinueModal) => {
  const selectedAgent = useSelector(
    (state: RootState) => state.agents.selectedAgent
  );
  const [agentInfo, setAgentInfo] = useState<IAgentData>();
  const { data, isLoading } = useGetAgentByAgentQuery(selectedAgent?.id ?? "");
  const tenant_id = localStorage.getItem("tenant_id") ?? "";
  const api_key =  localStorage.getItem("api_key") ?? "";

  const configContent = agentInfo?.data
    ? `{
    "title": "${agentInfo.data.name}",
    "provider": "${agentInfo.data.type}",
    "model": "${agentInfo.data.continue_model_name}",
    "apiBase": "${window._env_.REACT_APP_API_URL}/${EndpointConfig.AGENTS_URL}/${agentInfo.data.id}/${EndpointConfig.TENANT_URL}/${tenant_id}/${EndpointConfig.CONTINUE_URL}",
    "apiKey": "${api_key}"
  }`
    : "";

  useEffect(() => {
    setAgentInfo(data);
  }, [data]);

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      className={styles.continuemodal_container}
    >
      <DialogBackdrop className={styles.overlay} />
      <div className={styles.modal_container}>
        <DialogTitle className={styles.dialog_title}>
          Continue Dev Configuration
          <div className={styles.close_icon}>
            <XMarkIcon />
          </div>
        </DialogTitle>
        <div className={styles.horizontal_separator} />
        <DialogPanel className={styles.dialog}>
          <p>
            To configure the{" "}
            <a href="http://continue.dev/" target="_blank" rel="noreferrer">
              Continue
            </a>{" "}
            code assistant to work with our RAG, follow these steps:
          </p>
          <ol>
            <li>Download Continue from VS Code Marketplace;</li>
            <li>
              Enable it from VS Code, then go on the left-side toolbar and click
              on the Continue icon;
            </li>
            <li>
              Click on Configure Continue, then paste the following
              configuration inside "models":
            </li>
          </ol>
          {!isLoading ? (
            <code>
              <span>{"{"}</span>
              <span>{`"title": "${agentInfo?.data.name}"`},</span>
              <span>{`"provider": "${agentInfo?.data.type}"`},</span>
              <span>
                {`"model": "${agentInfo?.data.continue_model_name}"`},
              </span>
              <span>{`"apiBase": "${window._env_.REACT_APP_API_URL}/${EndpointConfig.AGENTS_URL}/${agentInfo?.data.id}/${EndpointConfig.TENANT_URL}/${tenant_id}/${EndpointConfig.CONTINUE_URL}"`},</span>
              <span>{`"apiKey": "${api_key}"`}</span>
              <span>{"}"}</span>
              <CopyToClipboardButton
                content={configContent}
                className={styles.copyButton}
              />
            </code>
          ) : (
            <Spinner />
          )}
        </DialogPanel>
      </div>
    </Dialog>
  );
};

export default ContinueModal;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UtilsState {
  [key: string]: boolean;
}

const initialState: UtilsState = {};

export const utilsSlice = createSlice({
  name: "utils",
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<{ key: string; value: boolean }>) => {
      state[action.payload.key] = action.payload.value;
    },
  },
});

export const { setIsLoading } = utilsSlice.actions;
export default utilsSlice.reducer;
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { ToastContainer } from "react-toastify";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useSelector } from "react-redux";

import SigninPage from "./pages/SigninPage";
import DashboardPage from "./pages/DashboardPage";
import Overlay from "./components/Overlay/Overlay";
import { configuration } from "./configs/MSALConfig";
import { RoutesEnum } from "./configs/routesEnum";
import { googleConfiguration } from "./configs/GoogleAuthConfig";
import { RootState } from "./store/store";
import WizardPage from "./pages/WizardPage";
import useBrowserHandler from './hooks/useBrowserHandler';

import "react-toastify/dist/ReactToastify.css";
import "./variables.css";
import "./App.scss";


function App() {
  const loadingStates = useSelector((state: RootState) => state.utils);
  const isLoading = Object.values(loadingStates).some((loading) => loading);
  const pca = new PublicClientApplication(configuration);

  useBrowserHandler(true);//boolean is to activate or deactivate 

  return (
    <div className="App">
      <MsalProvider instance={pca}>
        <GoogleOAuthProvider clientId={googleConfiguration.clientId}>
          <BrowserRouter>
            <Routes>
              <Route path={RoutesEnum.WELCOME_PAGE} element={<SigninPage />} />
              <Route path={`${RoutesEnum.WIZARD}/*`} element={<WizardPage />} />
              <Route path={RoutesEnum.DASHBOARD} element={<DashboardPage />} />
            </Routes>
          </BrowserRouter>
        </GoogleOAuthProvider>
      </MsalProvider>
      <ToastContainer />
      {isLoading && <Overlay />}
    </div>
  );
}

export default App;

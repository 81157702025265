import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {  IAgentResponse, IAgentsResponse } from "../types/IAgents";

interface AgentsState {
  selectedAgent: IAgentResponse | null;
  agents: IAgentsResponse;
}

const initialState: AgentsState = {
  selectedAgent: null,
  agents: {data: []},
};

export const agentsSlice = createSlice({
  name: "agents",
  initialState,
  reducers: {
    setSelectedAgent: (state, action: PayloadAction<IAgentResponse | null>) => {
      state.selectedAgent = action.payload;
    },
    agents: (state, action: PayloadAction<IAgentsResponse>) => {
      state.agents = action.payload;
    }
  },
});

export const { setSelectedAgent, agents } = agentsSlice.actions;
export default agentsSlice.reducer;
import { combineReducers, configureStore } from "@reduxjs/toolkit";

import userSlice from "../slices/userSlice";
import agentsSlice from '../slices/agentsSlice';
import { googleApi } from "../api/googleApi"
import { tenantApi } from '../api/tenantApi';
import { agentApi } from '../api/agentApi';
import { ingesterApi } from '../api/ingesterApi';
import { datastoreApi } from '../api/datastoreApi';
import { readerApi } from '../api/readerApi';
import utilsSlice from '../slices/utilsSlice';
import modalsSlice from "../slices/modalsSlice";
import { useDispatch } from 'react-redux';
import { userAuthApi } from '../api/userAuthApi';


const rootReducer = combineReducers({
  user: userSlice,
  modals: modalsSlice,
  agents: agentsSlice,
  utils: utilsSlice,
  [googleApi.reducerPath]: googleApi.reducer,
  [tenantApi.reducerPath]: tenantApi.reducer,
  [agentApi.reducerPath]: agentApi.reducer,
  [ingesterApi.reducerPath]: ingesterApi.reducer,
  [readerApi.reducerPath]: readerApi.reducer,
  [datastoreApi.reducerPath]: datastoreApi.reducer,
  [userAuthApi.reducerPath]: userAuthApi.reducer
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      googleApi.middleware,
      tenantApi.middleware,
      agentApi.middleware,
      ingesterApi.middleware,
      readerApi.middleware,
      datastoreApi.middleware,
      userAuthApi.middleware
    ),
});
export default store;
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = useDispatch.withTypes<AppDispatch>();

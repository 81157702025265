export interface IMessage {
    messageId: number;
    message: string;
    docs?: IDoc[];
    user: "user" | "bot";
}
export interface IMessages {
    chatId: number;
    messages: IMessage[];
}

export interface IPostMessage {
    query: string;
    user_filter: string;
}

export const initialMessages: IMessages = {
    chatId: 0,
    messages: []
};

export interface IDoc {
    id: string,
    title: string,
    uri: string,
    source: string,
    source_uri: string,
    text: string,
    anchor_id: string,
    page: string,
    index: number,
}

export interface IAnswer {
    answer: string,
    docs: IDoc[]
}
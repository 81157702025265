import { useCallback, useState } from "react";
import { Button } from "@headlessui/react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { useGetAgentByTenantQuery, useCreateAgentMutation, useUpdateAgentMutation } from "../../../api/agentApi";
import AgentForm from "./AgentForm";
import Spinner from "../../Spinner";
import { IAgentRequest, IAgentResponse, sortingKeys } from "../../../types/IAgents";
import { FormMode } from "../../../configs/FormMode";
import { useGetDatastoresByTenantQuery } from "../../../api/datastoreApi";
import AgentsList from "./AgentsList";
import Sorting from "../../Sorting";
import { titleCase } from "../../../utils/titleCase";
import { useHandleCreating, useHandleUpdateFeedback } from "../../../utils/settingsUtils";
import { updateSettings } from "../../../slices/modalsSlice";

import styles from "../Config.module.scss";
import { IError } from "../../../types/IErrors";
import { useTranslation } from "react-i18next";
import { hasDataProperty } from "../../../utils/checkError";


interface IAgentConfig {
  handleTabChange: (index: number) => void;
  formMode: string;
  setFormMode: (mode: string) => void;
}

const AgentConfig = ({ handleTabChange, formMode, setFormMode }: IAgentConfig) => {
  const dispatch = useDispatch();
  const tenant_id = localStorage.getItem("tenant_id") ?? "";
  const [selectedAgent, setSelectedAgent] = useState<IAgentResponse>();
  const [loadingState, setLoadingState] = useState<{ loading: boolean; id: string | null }>({ loading: false, id: null });
  const { t } = useTranslation();

  const { data } = useGetAgentByTenantQuery(tenant_id);
  const [sortedData, setSortedData] = useState<IAgentResponse[]>(data?.data ?? []);
  const { isLoading } = useGetAgentByTenantQuery(tenant_id);
  const { isLoading: isDatastoreLoading } = useGetDatastoresByTenantQuery(tenant_id);
  const [createAgent, { isLoading: isCreateLoading }] = useCreateAgentMutation();
  const [updateAgent, { isError: isUpdateError, isSuccess: isUpdateSuccess, isLoading: isUpdateLoading }] = useUpdateAgentMutation();
  const { data: dataStore } = useGetDatastoresByTenantQuery(tenant_id);

  const handleCreating = useHandleCreating({ formMode, setFormMode });//Modal Handler
  useHandleUpdateFeedback({
    isSuccess: isUpdateSuccess,
    isError: isUpdateError,
    successMessage: "Agent Updated",
    setLoadingState,
  });//Object Update Feedback Handler

  const handleUpdateAgent = useCallback(async (updatedAgent: IAgentRequest) => {
    setLoadingState({ loading: true, id: updatedAgent.id });
    try {
      await updateAgent(updatedAgent).unwrap();
      dispatch(updateSettings({ isFull: false, modalName: "settings", tab: "agents" }));
      toast.success('Agent Updated');
      setFormMode(FormMode.NONE);
    } catch (error) {
      if (hasDataProperty(error)) {
        const typedError = error as IError;
        if (typedError.data.message) {
          toast.error(t(`errors.${typedError.data.code}`));
          toast.warning(`Details: ${typedError.data.message}`);
        } else {
          toast.error(t(`errors.${typedError.data.code}`));
        }
      } else {
        toast.error(`${t("errors.generic")}`);
      }
    }
  }, [dispatch, setFormMode, t, updateAgent]);

  const handleCreateAgent = useCallback(async (newAgent: IAgentRequest) => {
    setLoadingState({ loading: true, id: newAgent.id });
    try {
      await createAgent(newAgent).unwrap();
      dispatch(updateSettings({ isFull: false, modalName: "settings", tab: "agents" }));
      toast.success('Agent Created');
      setFormMode(FormMode.NONE);
    } catch (error) {
      if (hasDataProperty(error)) {
        const typedError = error as IError;
        if (typedError.data.message) {
          toast.error(t(`errors.${typedError.data.code}`));
          toast.warning(`Details: ${typedError.data.message}`);
        } else {
          toast.error(t(`errors.${typedError.data.code}`));
        }
      } else {
        toast.error(`${t("errors.generic")}`);
      }
    }
  }, [createAgent, dispatch, setFormMode, t]);

  return (
    <div className={styles.container}>
      <div className={styles.header_container}>
        <Button
          className={styles.create_button}
          onClick={handleCreating}
          disabled={FormMode.CREATE && (dataStore?.data.length ?? 0) === 0}
        >
          {formMode === FormMode.CREATE || formMode === FormMode.EDIT ? (
            <div className={styles.arrow_wrapper}>
              <div className={styles.arrow}></div>
            </div>
          ) : (
            FormMode.CREATE
          )}
        </Button>
        {formMode === FormMode.NONE && (
          <Sorting
            data={data?.data}
            setSortedData={setSortedData}
            defaultSort={titleCase(sortingKeys[0])}
            options={sortingKeys}
          />
        )}
      </div>

      {(isLoading || isDatastoreLoading) && <Spinner />}
      {!(isLoading || isDatastoreLoading) &&
        (formMode === FormMode.NONE ? (
          <AgentsList
            setFormMode={setFormMode}
            setSelectedAgent={setSelectedAgent}
            loadingState={loadingState}
            setLoadingState={setLoadingState}
            handleTabChange={handleTabChange}
            sortedData={sortedData}
          />
        ) : (
          <>
            <span className={styles.tab_title}>
              {formMode === FormMode.CREATE
                ? "Create New Agent"
                : `Edit "${selectedAgent?.name}" Agent`}
            </span>
            <AgentForm
              submitFun={
                formMode === FormMode.CREATE
                  ? handleCreateAgent
                  : handleUpdateAgent
              }
              handleTabChange={handleTabChange}
              mode={formMode}
              agent={selectedAgent}
              isLoading={isCreateLoading || isUpdateLoading}
            />
          </>
        ))}
    </div>
  );
};

export default AgentConfig;


import {
  useCallback,
  useState
} from "react";

import { Button } from "@headlessui/react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { useCreateDatastoreMutation, useGetDatastoresByTenantQuery, useUpdateDatastoreMutation } from "../../../api/datastoreApi";
import { updateSettings } from "../../../slices/modalsSlice";

import Spinner from "../../Spinner";
import { FormMode } from "../../../configs/FormMode";
import { IDatastoreRequest, IDatastoreResponse, sortingKeys } from "../../../types/IDatastore";
import DatastoreForm from "./DatastoreForm/DatastoreForm";
import DatastoresList from "./DatastoresList";
import Sorting from "../../Sorting";
import { useHandleCreating, useHandleUpdateFeedback } from "../../../utils/settingsUtils";

import styles from "../Config.module.scss";
import { useTranslation } from "react-i18next";
import { IError } from "../../../types/IErrors";
import { hasDataProperty } from "../../../utils/checkError";


interface IDatastoreConfig {
  formMode: string;
  setFormMode: (mode: string) => void;
}

const DatastoreConfig = ({ formMode, setFormMode }: IDatastoreConfig) => {
  const dispatch = useDispatch();
  const tenant_id = localStorage.getItem("tenant_id") ?? "";
  const [selectDatastore, setSelectDatastore] = useState<IDatastoreResponse>();
  const [loadingState, setLoadingState] = useState<{ loading: boolean; id: string | null; }>({ loading: false, id: null });

  const { data } = useGetDatastoresByTenantQuery(tenant_id);
  const [sortedData, setSortedData] = useState<IDatastoreResponse[]>(data?.data ?? []);
  const { isLoading } = useGetDatastoresByTenantQuery(tenant_id);
  const [createDatastore, { isLoading: isCreateLoading }] = useCreateDatastoreMutation();
  const [updateDatastore, { isError: isUpdateError, isSuccess: isUpdateSuccess, isLoading: isUpdateLoading }] = useUpdateDatastoreMutation();
  const { t } = useTranslation()

  const handleCreating = useHandleCreating({ formMode, setFormMode });//Modal Handler
  useHandleUpdateFeedback({
    isSuccess: isUpdateSuccess,
    isError: isUpdateError,
    successMessage: "Datastore Updated",
    setLoadingState,
  });//Object Update Feedback Handler

  const handleUpdateDatastore = useCallback(async (updatedDatastore: IDatastoreRequest) => {
    setLoadingState({ loading: true, id: updatedDatastore.id });
    try {
      await updateDatastore(updatedDatastore).unwrap();
      dispatch(updateSettings({ isFull: false, modalName: "settings", tab: "datastore" }));
      toast.success('Data Store Updated');
      setFormMode(FormMode.NONE);
    } catch (error) {
      if (hasDataProperty(error)) {
        const typedError = error as IError;
        if (typedError.data.message) {
          toast.error(t(`errors.${typedError.data.code}`));
          toast.warning(`Details: ${typedError.data.message}`);
        } else {
          toast.error(t(`errors.${typedError.data.code}`));
        }
      } else {
        toast.error(`${t("errors.generic")}`);
      }
    }
    setLoadingState({ loading: false, id: updatedDatastore.id });
  }, [dispatch, setFormMode, t, updateDatastore]);

  const handleCreateDatastore = useCallback(async (newDatastore: IDatastoreRequest) => {
    setLoadingState({ loading: true, id: newDatastore.id });
    try {
      await createDatastore(newDatastore).unwrap();
      dispatch(updateSettings({ isFull: false, modalName: "settings", tab: "datastore" }));
      toast.success('Data Store Created');
      setFormMode(FormMode.NONE);
    } catch (error) {
      if (hasDataProperty(error)) {
        const typedError = error as IError;
        if (typedError.data.message) {
          toast.error(t(`errors.${typedError.data.code}`));
          toast.warning(`Details: ${typedError.data.message}`);
        } else {
          toast.error(t(`errors.${typedError.data.code}`));
        }
      } else {
        toast.error(`${t("errors.generic")}`);
      }
    }
  }, [createDatastore, dispatch, setFormMode, t]);

  return (
    <div className={styles.container}>
      <div className={styles.header_container}>
        <Button className={styles.create_button} onClick={handleCreating}>
          {formMode === FormMode.CREATE || formMode === FormMode.EDIT ? (
            <div className={styles.arrow_wrapper}>
              <div className={styles.arrow}></div>
            </div>
          ) : (
            FormMode.CREATE
          )}
        </Button>
        {formMode === FormMode.NONE && (
          <Sorting
            data={data?.data}
            setSortedData={setSortedData}
            defaultSort={"Last ingestion"}
            options={sortingKeys}
          />
        )}
      </div>
      {isLoading && <Spinner />}
      {!isLoading &&
        (formMode === FormMode.NONE ? (
          <DatastoresList
            setFormMode={setFormMode}
            setSelectDatastore={setSelectDatastore}
            loadingState={loadingState}
            setLoadingState={setLoadingState}
            sortedData={sortedData}
          />
        ) : (
          <>
            <span className={styles.tab_title}>
              {formMode === FormMode.CREATE
                ? "Create New Datastore"
                : `Edit "${selectDatastore?.name}" Datastore`}
            </span>
            <DatastoreForm
              submitFun={
                formMode === FormMode.CREATE
                  ? handleCreateDatastore
                  : handleUpdateDatastore
              }
              mode={formMode}
              datastore={selectDatastore}
              isLoading={isCreateLoading || isUpdateLoading}
            />
          </>
        ))}
    </div>
  );
};

export default DatastoreConfig;

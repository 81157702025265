import { useCallback, useState, useEffect } from "react";
import {
  Field,
  Fieldset,
  Input,
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import { useDispatch } from "react-redux";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { toast } from "react-toastify";
import * as Yup from "yup";

import { initialAgentState, IAgentRequest, IAgentResponse } from "../../../../types/IAgents";
import { FormMode } from "../../../../configs/FormMode";
import { useGetDatastoresByTenantQuery } from "../../../../api/datastoreApi";
import { types, llm_models, search_types} from "../../../../configs/AgentConfig";
import { updateSettings } from "../../../../slices/modalsSlice";
import Spinner from "../../../Spinner";
import { agentConfigSchema, agentFormSchema } from "../../schema";


import styles from "../..//Form.module.scss";

interface IAgentForm {
  submitFun: (form: IAgentRequest) => void;
  handleTabChange: (index: number) => void;
  mode: string;
  agent?: IAgentResponse;
  isLoading: boolean;
}

const AgentForm = ({submitFun, handleTabChange, mode, agent, isLoading }: IAgentForm) => {
  const dispatch = useDispatch();
  const tenant_id = localStorage.getItem("tenant_id") ?? "";
  const [agentForm, setAgentForm] = useState<IAgentResponse>( mode === FormMode.EDIT && agent ? agent : initialAgentState);

  const { data: dataStore } = useGetDatastoresByTenantQuery(tenant_id);

  const handelInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setAgentForm((prevForm) => ({ ...prevForm, [name]: value }));
  },[]);

  const handleSelectChange = useCallback((name: string, value: string) => {
    if (name === "datastore_id") {
      if(dataStore?.data.find(dataStore=>dataStore.id===value)?.last_ingestion===null){
        toast.warning("Warning: No ingestion has been done for the selected datastore.")
      }
      setAgentForm((prevForm) => ({
        ...prevForm,
        datastore_id: { ...prevForm.datastore_id, id: value },
      }));

      if (value === "create_new_datastore") {
        handleTabChange(2);
      }
    } else if (name === "llm_model" || name === "search_type") {
      setAgentForm((prevForm) => ({
        ...prevForm,
        conf: { ...prevForm.conf, [name]: value },
      }));
    } else {
        setAgentForm((prevForm) => ({
          ...prevForm,
          [name]: value,
        }));
    }
  },[dataStore?.data, handleTabChange]);

  const handleSubmit = useCallback(async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      await agentFormSchema.validate(agentForm, { abortEarly: false });

      if (agentForm.type) {
        await agentConfigSchema.validate(agentForm.conf, { abortEarly: false });
      }

      const finalForm: IAgentRequest = {
        ...agentForm,
        datastore_id: agentForm.datastore_id.id,
        tenant_id: tenant_id,
        agent_id: agentForm.id,
      };

      submitFun(finalForm);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          if (error.message) {
            toast.warning(error.message); // Mostra un toast per ogni errore
          }
        });
        toast.error("Form validation failed"); // Notifica generale per gli errori
      }
    }
  }, [agentForm, tenant_id, submitFun]);

  useEffect(() => {
    const hasNonEmptyForm =
      mode === FormMode.CREATE
        ? JSON.stringify(agentForm) !== JSON.stringify(initialAgentState)
        : JSON.stringify(agentForm) !== JSON.stringify(agent);

    if (hasNonEmptyForm) {
      dispatch(
        updateSettings({
          isFull: true,
          modalName: "settings",
          tab: "agents",
        })
      );
    } else {
      dispatch(
        updateSettings({
          isFull: false,
          modalName: "settings",
          tab: "agents",
        })
      );
    }
  }, [agent, agentForm, dispatch, mode]);

  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      <Fieldset as="div" className={styles.fields}>
        <Field as="div" className={styles.field}>
          <Label className={styles.label}>Name</Label>
          <Input
            className={styles.input}
            minLength={1}
            maxLength={30}
            required
            name="name"
            placeholder="Agent name"
            onChange={handelInputChange}
            value={agentForm.name}
            disabled={isLoading}
          />
        </Field>

        <Field as="div" className={styles.field}>
          <Label className={styles.label}>Datastore: </Label>
          <Listbox
            value={agentForm?.datastore_id.id}
            onChange={(value: string) =>
              handleSelectChange("datastore_id", value)
            }
            as="div"
            className={styles.list_box}
          >
            <ListboxButton className={styles.list_button}>
              <span className={styles.agent_name}>
                {dataStore?.data.find(
                  (datastore) => datastore.id === agentForm?.datastore_id.id
                )?.name ?? "Select a datastore"}
              </span>
              <ChevronDownIcon className={styles.list_icon} />
            </ListboxButton>
            <ListboxOptions
              anchor="bottom start"
              className={styles.list_options}
            >
              {dataStore?.data && dataStore?.data.length > 0 ? (
                dataStore?.data.map((datastore) => (
                  <ListboxOption
                    key={datastore.id}
                    value={datastore.id}
                    className={styles.list_option}
                    disabled={isLoading}
                  >
                    {datastore.name}
                  </ListboxOption>
                ))
              ) : (
                <ListboxOption
                  key={crypto.randomUUID()}
                  value="create_new_datastore"
                  className={styles.list_option}
                >
                  <span>Create new datastore...</span>
                </ListboxOption>
              )}
            </ListboxOptions>
          </Listbox>
        </Field>

        <Field as="div" className={styles.field}>
          <Label className={styles.label}>Agent type: </Label>
          <Listbox
            value={agentForm.type}
            onChange={(value: string) => handleSelectChange("type", value)}
            as="div"
            className={styles.list_box}
          >
            <ListboxButton className={styles.list_button}>
              <span className={styles.agent_name}>
                {agentForm.type || "Select a type"}
              </span>
              <ChevronDownIcon className={styles.list_icon} />
            </ListboxButton>
            <ListboxOptions
              anchor="bottom start"
              className={styles.list_options}

            >
              {types.map((type) => (
                <ListboxOption
                  key={crypto.randomUUID()}
                  value={type}
                  className={styles.list_option}
                  disabled={isLoading}
                >
                  {type}
                </ListboxOption>
              ))}
            </ListboxOptions>
          </Listbox>
        </Field>

        <div className={styles.jsonForm}>
          <p className={styles.jsonFormTitle}>Agent Config:</p>
          <div className={styles.jsonFields}>
            {agentForm.type ? (
              <>
                <Field as="div" className={styles.field}>
                  <Label className={styles.label}>LLM Model: </Label>
                  <Listbox
                    value={agentForm.conf.llm_model}
                    onChange={(value: string) =>
                      handleSelectChange("llm_model", value)
                    }
                    as="div"
                    className={styles.list_box}
                  >
                    <ListboxButton className={styles.list_button}>
                      <span className={styles.agent_name}>
                        {agentForm.conf.llm_model || "Select a LLM Model"}
                      </span>
                      <ChevronDownIcon className={styles.list_icon} />
                    </ListboxButton>
                    <ListboxOptions
                      anchor="bottom start"
                      className={styles.list_options}
                    >
                      {llm_models.map((llm_model) => (
                        <ListboxOption
                          key={crypto.randomUUID()}
                          value={llm_model}
                          className={styles.list_option}
                          disabled={isLoading}
                        >
                          {llm_model}
                        </ListboxOption>
                      ))}
                    </ListboxOptions>
                  </Listbox>
                </Field>
                <Field as="div" className={styles.field}>
                  <Label className={styles.label}>Search Type: </Label>
                  <Listbox
                    value={agentForm.conf.search_type}
                    onChange={(value: string) =>
                      handleSelectChange("search_type", value)
                    }
                    as="div"
                    className={styles.list_box}
                  >
                    <ListboxButton className={styles.list_button}>
                      <span className={styles.agent_name}>
                        {agentForm.conf.search_type || "Select a search type"}
                      </span>
                      <ChevronDownIcon className={styles.list_icon} />
                    </ListboxButton>
                    <ListboxOptions
                      anchor="bottom start"
                      className={styles.list_options}
                    >
                      {search_types.map((search_type) => (
                        <ListboxOption
                          key={crypto.randomUUID()}
                          value={search_type}
                          className={styles.list_option}
                          disabled={isLoading}
                        >
                          {search_type}
                        </ListboxOption>
                      ))}
                    </ListboxOptions>
                  </Listbox>
                </Field>{" "}
              </>
            ) : (
              <p>Select a Model Provider type first!</p>
            )}
          </div>
        </div>
        <Field as="div" className={styles.field}>
          {isLoading ? <Spinner /> :
          <button type="submit" className={styles.submit_button}>
            Submit
          </button>}
        </Field>
      </Fieldset>
    </form>
  );
};

export default AgentForm;

import { useState, useCallback, useEffect } from "react";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Tab,
  TabGroup,
  TabList,
  TabPanels,
  TabPanel,
  DialogBackdrop,
} from "@headlessui/react";
import { useSelector } from "react-redux";
import { XMarkIcon } from "@heroicons/react/24/solid";

import {
  ModalsState,
  setIsOpenForMessage,
  setIsOpenForSettings,
  updateMessage,
  updateSettings,
} from "../../slices/modalsSlice";
import ReaderConfig from "./ReaderConfig/ReaderConfig";
import AgentConfig from "./AgentConfig/AgentConfig";
import { useGetReadersByTenantQuery } from "../../api/readerApi";
import { useGetDatastoresByTenantQuery } from "../../api/datastoreApi";
import { useGetAgentByTenantQuery } from "../../api/agentApi";
import { useGetIngesterByTenantQuery } from "../../api/ingesterApi";
import { ITab, initialTabs } from "../../types/ITabs";
import { RootState, useAppDispatch } from "../../store/store";
import MessageModal from "../MessageModal";
import IngesterConfig from "./IngesterConfig/IngesterConfig";
import DatastoreConfig from "./DatastoreConfig/DatastoreConfig";
import { FormMode } from "../../configs/FormMode";
import { useGetTenantQuery } from "../../api/tenantApi";

import styles from "./SettingsModal.module.scss";

interface ISettingsModal {
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
}

interface IYesMethods {
  back: () => void;
  quit: () => void;
  tab: () => void;
}

const SettingsModal = ({ isOpen, setIsOpen }: ISettingsModal) => {
  const [selectedIndex, setSelectedIndex] = useState<number>();
  const [tabs, setTabs] = useState<ITab[]>(initialTabs);
  const [formMode, setFormMode] = useState(FormMode.NONE);
  const vendor_id = localStorage.getItem('vendor_id') ?? '';
  const tenant_id = localStorage.getItem("tenant_id") ?? "";

  const modals = useSelector((state: RootState) => state.modals);
  const dispatch = useAppDispatch();

  const messageModalsYesMethods: IYesMethods = {
    quit: () => {
      dispatch(setIsOpenForMessage(false));
      dispatch(setIsOpenForSettings(false));
    },
    back: () => {
      dispatch(setIsOpenForMessage(false));
      dispatch(updateSettings({ isFull: false, isOpen: true }));
      setFormMode(FormMode.NONE);
    },
    tab: ()=>{
      dispatch(setIsOpenForMessage(false));
      dispatch(updateSettings({ isFull: false, isOpen: true }));
      setFormMode(FormMode.NONE);
      setSelectedIndex(modals.message.destinationTabIndex);
    }
  };

  const { data: tenant } = useGetTenantQuery(vendor_id);
  const isWizardComplete = tenant?.data?.is_wizard_complete ?? false;
  const { data: readers } = useGetReadersByTenantQuery(tenant_id, { skip: isWizardComplete });
  const { data: datastores } = useGetDatastoresByTenantQuery(tenant_id, { skip: isWizardComplete });
  const { data: ingesters } = useGetIngesterByTenantQuery(tenant_id, { skip: isWizardComplete });
  const { data: agents } = useGetAgentByTenantQuery(tenant_id, { skip: isWizardComplete });

  useEffect(() => {
    readers?.data &&
      setTabs((prevTabs: ITab[]) => {
        return prevTabs.map((tab) => {
          if (tab.name === "readers") {
            return {
              ...tab,
              completed: (readers?.data?.length ?? 0) > 0,
              isSetted: true,
            };
          }
          return tab;
        });
      });

    datastores?.data &&
      setTabs((prevTabs: ITab[]) => {
        return prevTabs.map((tab) => {
          if (tab.name === "datastores") {
            return {
              ...tab,
              completed: (datastores?.data?.length ?? 0) > 0,
              isSetted: true,
            };
          }
          return tab;
        });
      });

    ingesters?.data &&
      setTabs((prevTabs: ITab[]) => {
        return prevTabs.map((tab) => {
          if (tab.name === "ingesters") {
            return {
              ...tab,
              completed: (ingesters?.data?.length ?? 0) > 0,
              isSetted: true,
            };
          }
          return tab;
        });
      });
    agents?.data &&
      setTabs((prevTabs: ITab[]) => {
        return prevTabs.map((tab) => {
          if (tab.name === "agents") {
            return {
              ...tab,
              completed: (agents?.data?.length ?? 0) > 0,
              isSetted: true,
            };
          }
          return tab;
        });
      });
  }, [readers, datastores, ingesters, agents]);

  const handleTabChange = useCallback((index: number) => {
      if (
        modals["settings" as keyof ModalsState].isFull &&
        modals["settings" as keyof ModalsState].isOpen
      ) {
        dispatch(
          updateMessage({
            isOpen: true,
            modalName: "message",
            destinationTabIndex:index,
            yesMethod: "tab",
          })
        );
      } else {
        setFormMode(FormMode.NONE);
        setSelectedIndex(index);
      }
    },
    [dispatch, modals]
  );

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      className={styles.settingsmodal_container}
    >
      <DialogBackdrop className={styles.overlay} />
      <div className={styles.modal_container}>
        <DialogTitle className={styles.dialog_title}>
          Enterprise Settings
          <div className={styles.close_icon}>
            <XMarkIcon />
          </div>
        </DialogTitle>
        <div className={styles.horizontal_separator} />
        <DialogPanel className={styles.dialog}>
          <TabGroup
            className={styles.tab_container}
            selectedIndex={selectedIndex}
            onChange={(index) => handleTabChange(index)}
          >
            <TabList className={styles.tab_list}>
              <Tab className={styles.tab}>Tenant</Tab>

              {tabs.map((tab: ITab) => (
                <Tab
                  key={tab.id}
                  className={`${styles.tab} ${
                    tab.completed && styles.completed
                  }`}
                >
                  {tab?.label}
                </Tab>
              ))}
            </TabList>
            <TabPanels className={styles.tab_panels}>
              <TabPanel className={styles.tab_panel}>Tenant</TabPanel>
              <TabPanel className={styles.tab_panel}>
                <ReaderConfig
                  formMode={formMode}
                  setFormMode={(state: any) => setFormMode(state)}
                />{" "}
              </TabPanel>
              <TabPanel className={styles.tab_panel}>
                <DatastoreConfig
                  formMode={formMode}
                  setFormMode={(state: any) => setFormMode(state)}
                />{" "}
              </TabPanel>
              <TabPanel className={styles.tab_panel}>
                <IngesterConfig
                  handleTabChange={handleTabChange}
                  formMode={formMode}
                  setFormMode={(state: any) => setFormMode(state)}
                />{" "}
              </TabPanel>
              <TabPanel className={styles.tab_panel}>
                <AgentConfig
                  handleTabChange={handleTabChange}
                  formMode={formMode}
                  setFormMode={(state: any) => setFormMode(state)}
                />{" "}
              </TabPanel>
            </TabPanels>
          </TabGroup>
        </DialogPanel>
        {modals.message.isOpen && (
          <div className={styles.overlay}>
            <MessageModal
              isOpen={modals.message.isOpen}
              setIsOpen={(state) => dispatch(setIsOpenForMessage(state))}
              message="Unsaved changes detected. Any unsaved changes will be lost. Do you want to proceed?"
              title="Warning!"
              options={[
                {
                  method: modals.message.yesMethod
                    ? messageModalsYesMethods[
                        modals.message.yesMethod as keyof IYesMethods
                      ]
                    : () => {},
                  text: "Yes",
                },
                {
                  method: () => dispatch(setIsOpenForMessage(false)),
                  text: "No",
                },
              ]}
            />
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default SettingsModal;



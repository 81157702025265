import { object, string, lazy, mixed } from 'yup';

export const agentFormSchema = object({
  name: string()
    .min(1, "Name is too short")
    .max(30, "Name is too long")
    .matches(/^[a-zA-Z0-9]/, "Name cannot start with a special character")
    .trim()
    ,
  type: string().required("AI Model Provider is required"),
  datastore_id: object({
    id: string().required("Datastore is required"),
  }).required("Datastore is required"),
});


export const agentConfigSchema = object({
  llm_model: string().required("LLM Model is required"),
  search_type: string().required("Search Type is required"),
});

export const geminiConfSchema = object({
  project_id: string()
    .min(6)
    .max(30),
  location_id: string()
  .matches(/^[a-zA-Z\s]+$/, "Location ID cannot contain numbers or special characters") 
  .trim()
})

export const datastoreFormSchema = object({
  name: string()
  .min(1, "Name is too short")
  .max(30, "Name is too long")
  .matches(/^[a-zA-Z0-9]/, "Name cannot start with a special character")
  .trim(),
  description: string()
    .min(1, "Description is too short")
    .max(100, "Description is too long"),
  datastore_type: string().required("Datastore Type is required"),
});

export const readerGiteaConfSchema =  object({
  domain: string()
  .min(1, "domain is too short")
  .matches(/^(((http|https):\/\/|)?[a-z0-9]+([\\\-\\.]{1}[a-z0-9]+)*\.[a-z]{2,6}(:[0-9]{1,5})?(\/.*)?)$/, "domain is wrong")
  .trim(),
  token: string()
  .min(1, "Token is too short")
  .trim(),
  username: string()
  .min(1, "usernameis too short")
  .matches(/^[\da-zA-Z][-.\w]*$/, "Username is not valid")
  .trim(),
})

export const readerSharepointConfSchema = object({
  client_id: string()
  .min(1, "Client Id is too short")
  .matches(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/, 'Client Id is wrong')
  .trim(),
  client_secret: string()
  .min(1, "Client secret is too short")
  .max(40, "Client secret is too long")
  .matches(/^[a-zA-Z0-9\-_.~]{1,40}$/, 'Client Secret is wrong')
  .trim(),
  ms_tenant_id: string()
  .min(1, "ms_tenant_id is too short")
  .matches(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/, 'Microsoft Sharepoint Tenant Id is wrong')// GUID REGEX
  .trim(),
})

export const readerFormSchema = object({
  name: string()
  .min(1, "Name is too short")
  .max(30, "Name is too long")
  .matches(/^[a-zA-Z0-9]/, "Name cannot start with a special character")
  .trim(),
  description: string()
    .min(1, "Description is too short")
    .max(100, "Description is too long"),
  source_type: string().required("Source Type is required"),
  conf: lazy(value => {
    if (typeof value === 'object' && value !== null) {
      return object().shape({
        ...readerGiteaConfSchema.fields,
        ...readerSharepointConfSchema.fields,
      }).required("Configuration is required");
    }
    return mixed().oneOf([readerGiteaConfSchema, readerSharepointConfSchema]).required("Configuration is required");
  }),
})

export const ingesterFormSchema = object({
  name: string()
  .min(1, "Name is too short")
  .max(30, "Name is too long")
  .matches(/^[a-zA-Z0-9]/, "Name cannot start with a special character")
  .trim(),
  description: string()
    .min(1, "Description is too short")
    .max(100, "Description is too long"),
  reader_id: object({
    id: string().required("Data Origin is required"),
  }),
  datastore_id: object({
    id: string().required("Datastore is required"),
  })
})
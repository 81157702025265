import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { toast } from "react-toastify";

import { EndpointConfig } from "../configs/EndpointConfig";
import {
  ICronejob,
  IIngesterObject,
  IIngesterRequest,
  IIngesters,
} from "../types/IIngester";

const baseQuery = fetchBaseQuery({
  baseUrl: window._env_.REACT_APP_API_URL,
  prepareHeaders: (headers) => {
    headers.set("Content-Type", "application/json");
    const token = localStorage.getItem("access_token");

    if (token) {
      headers.set("Authorization", `${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    localStorage.clear();
    sessionStorage.clear();
    toast.warning("Your session has expired. Please log in again.");
  }
  return result;
};

export const ingesterApi = createApi({
  tagTypes: ["ingesterApi"],
  reducerPath: "ingesterApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getIngester: builder.query<IIngesterObject, string>({
      // get ingesters by ingester_id
      query: (ingester_id) => {
        return {
          url: `/${EndpointConfig.INGESTER_URL}/${ingester_id}`,
          method: "GET",
        };
      },
      providesTags: ["ingesterApi"],
    }),
    getIngesterByTenant: builder.query<IIngesters, string>({
      // get ingesters by tenant_id
      query: (tenant_id) => {
        return {
          url: `/${EndpointConfig.INGESTER_URL}`,
          params: {tenant_id: tenant_id},
          method: "GET",
        };
      },
      providesTags: ["ingesterApi"],
    }),
    createIngester: builder.mutation<string, IIngesterRequest>({
      query: (params) => {
        const { ingester_id, ...props } = params;
        return {
          url: `/${EndpointConfig.INGESTER_URL}`,
          method: "POST",
          body: props,
        };
      },
      invalidatesTags: ["ingesterApi"],
    }),
    configureCronjob: builder.mutation<string, ICronejob>({
      query: (params) => ({
        url: `/${EndpointConfig.INGESTER_URL}/${params.ingester_id}/plan`,
        method: "POST",
        body: params.cron_expression,
      }),
      invalidatesTags: ["ingesterApi"],
    }),
    updateIngester: builder.mutation<string, IIngesterRequest>({
      query: (params) => {
        const { tenant_id, ingester_id, ...props } = params;
        return {
          url: `/${EndpointConfig.INGESTER_URL}/${params.ingester_id}`,
          method: "PUT",
          body: props,
        };
      },
      invalidatesTags: ["ingesterApi"],
    }),
    deleteIngester: builder.mutation<string, string>({
      query: (ingester_id) => ({
        url: `/${EndpointConfig.INGESTER_URL}/${ingester_id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ingesterApi"],
    }),
    performIngestion: builder.query<string, string>({
      query: (ingester_id) => {
        return {
          url: `/${EndpointConfig.INGESTER_URL}/${ingester_id}/ingest`,
          method: "GET",
        };
      },
    }),
  }),
});

export const {
  useGetIngesterQuery,
  useGetIngesterByTenantQuery,
  useCreateIngesterMutation,
  useConfigureCronjobMutation,
  useUpdateIngesterMutation,
  useDeleteIngesterMutation,
  useLazyPerformIngestionQuery,
} = ingesterApi;

export interface IReaders {
    data: IReaderResponse[];
}

export interface IReaderObject {
    data: IReaderResponse;
}

export interface IReaderResponse extends IReader {
    tenant_id: TenantId;

}
export interface IReaderRequest extends IReader {
    tenant_id: string;

}

export interface IReader {
    id: string;
    name: string;
    description: string;
    source_type: string;
    conf:ReaderConf;
}

type ReaderConf = ISharepointConf | IGiteaConf;
export interface TenantId {
    id: string;
    collection: string;
}

export interface ISharepointConf { // configurazioni per Sharepoint
    type?: "sharepoint",
    client_id?: string;
    client_secret?: string;
    ms_tenant_id?: string;
    site_name: string;
    folder_path?: string;
}
export interface IGiteaConf { // configurazioni per Gitea
    type?: "gitea",
    domain?: string;
    username?: string;
    token?: string;
    org_name?: string;
    repo_name?: string;
}

export const initialReaderState: IReader = {
    id: '',
    name: '',
    description: '',
    source_type: '',
    conf: {
    },
}

export const sortingKeys: string[] = ["id", "name", "source_type"]

import styles from "./CodeBlockRenderer.module.scss";

interface ICodeBlockRenderer {
  children: React.ReactNode;
  className: string;
}
const CodeBlockRenderer = ({ children, className }: ICodeBlockRenderer) => {
  const language = className ? className.replace(/language-/, "") : "";

  return (
    <pre className={styles.pre}>
      <span className={styles.language}>{language}</span>
      <code className={styles.code}>{children}</code>
    </pre>
  );
};
export default CodeBlockRenderer;

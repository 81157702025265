import { MouseEvent, useCallback } from "react";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/react";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/solid";
import { toast } from "react-toastify";

import { useDeleteDatastoreMutation } from "../../../../api/datastoreApi";
import Spinner from "../../../Spinner";
import { FormMode } from "../../../../configs/FormMode";
import { IDatastoreResponse, IGeminiConf } from "../../../../types/IDatastore";

import styles from "../../List.module.scss";
import { useTranslation } from "react-i18next";
import { IError } from "../../../../types/IErrors";
import { hasDataProperty } from "../../../../utils/checkError";

interface IDatastoresList {
  setFormMode: (mode: FormMode) => void;
  setSelectDatastore: (reader: IDatastoreResponse) => void;
  loadingState: {
    loading: boolean;
    id: string | null;
  };
  setLoadingState: (state: { loading: boolean; id: string | null }) => void;
  sortedData: IDatastoreResponse[];
}

const Datastores = ({ setFormMode, setSelectDatastore, loadingState, setLoadingState, sortedData, }: IDatastoresList) => {
  const [deleteDatastore] = useDeleteDatastoreMutation();

  const { t } = useTranslation()
  const modifyDatastoreFunc = useCallback(
    (e: MouseEvent<HTMLButtonElement>, reader: IDatastoreResponse) => {
      e.stopPropagation();
      setSelectDatastore(reader);
      setFormMode(FormMode.EDIT);
    }, [setFormMode, setSelectDatastore]);

  const deleteDatastoreFunc = useCallback(async (e: MouseEvent<HTMLButtonElement>, datastoreId: string) => {
    e.stopPropagation();

    setLoadingState({ loading: true, id: datastoreId });
    try {
      await deleteDatastore(datastoreId).unwrap();
      toast.success("Data Origin removed");
    } catch (error) {
      if (hasDataProperty(error)) {
        const typedError = error as IError;
        if (typedError.data.message) {
          toast.error(t(`errors.${typedError.data.code}`));
          toast.warning(`Details: ${typedError.data.message}`);
        } else {
          toast.error(t(`errors.${typedError.data.code}`));
        }
      } else {
        toast.error(`${t("errors.generic")}`);
      }
    }
    setLoadingState({ loading: false, id: datastoreId });

  }, [deleteDatastore, setLoadingState, t]);

  return (
    <div className={styles.list_container}>
      {(sortedData?.length ?? 0) > 0 ? (
        sortedData.map((datastore: IDatastoreResponse) => (
          <div
            className={styles.disclosure_container}
            key={crypto.randomUUID()}
          >
            <Disclosure key={crypto.randomUUID()}>
              <DisclosureButton
                as="div"
                key={crypto.randomUUID()}
                className={styles.disclosure_button}
              >
                <span className={styles.disclosure_title}>
                  {datastore.name}
                </span>
                {!loadingState.loading || loadingState.id !== datastore.id ? (
                  <div className={styles.disclosure_controls}>
                    <button
                      onClick={(e) => modifyDatastoreFunc(e, datastore)}
                      title="Modify"
                    >
                      <PencilIcon width={20} />
                    </button>
                    <button
                      onClick={(e) => deleteDatastoreFunc(e, datastore.id)}
                      title="Remove"
                    >
                      <TrashIcon width={20} />
                    </button>
                  </div>
                ) : (
                  <Spinner />
                )}
              </DisclosureButton>
              <DisclosurePanel
                key={crypto.randomUUID()}
                className={styles.disclosure_panel}
              >
                <ul>
                  <li>
                    <span className={styles.field_title}>Id:</span>{" "}
                    {datastore.id}
                  </li>
                  <li>
                    <span className={styles.field_title}>Description:</span>{" "}
                    {datastore.description}
                  </li>
                  <li>
                    <span className={styles.field_title}>Source type:</span>{" "}
                    {datastore.datastore_type}
                  </li>
                  <li>
                    <span className={styles.field_title}>Last ingestion:</span>{" "}
                    {datastore.last_ingestion
                      ? datastore.last_ingestion
                      : "not ingested"}
                  </li>
                  <li>
                    <span className={styles.field_title}>
                      Datastore Config:
                    </span>{" "}
                    <ul>
                      {Object.keys(datastore.conf).map((confKey, i) => (
                        <li key={crypto.randomUUID()}>
                          <span className={styles.field_title}>{confKey}:</span>{" "}
                          {datastore.conf[confKey as keyof IGeminiConf]}
                        </li>
                      ))}
                    </ul>
                  </li>
                </ul>
              </DisclosurePanel>
            </Disclosure>
          </div>
        ))
      ) : (
        <p className={styles.no_data}>
          No Data Stores available. Click on "Create" to add a Data Store.
        </p>
      )}
    </div>
  );
};

export default Datastores;

import { useMsal } from "@azure/msal-react";
import { TokenResponse, googleLogout, useGoogleLogin, } from "@react-oauth/google";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

import { RoutesEnum } from "../configs/routesEnum";
import { useLazyGetGoogleUserInfoQuery } from "../api/googleApi";
import { setSelectedAgent } from "../slices/agentsSlice";
import { useMsalAuthMutation, useGoogleAuthMutation } from "../api/userAuthApi";
import { AccountInfo } from "@azure/msal-browser";


export const useAuth = () => {
    const [isAuth, setIsAuth] = useState<boolean>(false);

    const dispatch = useDispatch();
    const { instance } = useMsal();
    const navigate = useNavigate();
    const [getGoogleUserInfo] = useLazyGetGoogleUserInfoQuery();
    const [msalAuth] = useMsalAuthMutation();
    const [googleAuth] = useGoogleAuthMutation();

    const authProvider = localStorage.getItem('authProvider');

    const initializeSignIn = useCallback(async () => {
            try {
                const response = await instance.loginPopup({scopes: [], prompt: "select_account"});

                if (response) {
                    localStorage.setItem("authProvider", 'microsoft');
                    localStorage.setItem("isAuth", JSON.stringify(true));
                    localStorage.setItem("vendor_id", response.tenantId);
                }

                try {
                    const tokenResponse = await instance.acquireTokenSilent({ scopes: ["user.read"], account: response.account, })
                    const data = await msalAuth({ mail: tokenResponse.account.username, access_token: tokenResponse.accessToken, vendor_id: response.account.tenantId }).unwrap();

                    if (data) {
                        setIsAuth(true);
                        localStorage.setItem("access_token", data.auth_token );
                        localStorage.setItem("api_key", data.api_key);

                        if (data.tenant_info.id)
                            localStorage.setItem("tenant_id", data.tenant_info.id);
                    }
                } catch (error) {
                    toast.warning(`${error}`);
                }
            } catch (error) {
                toast.warning(`${error}`);
            }
    }, [instance, msalAuth]);

const onSuccess = (tokenResponse: TokenResponse) => {(async () => {
    try {
        sessionStorage.clear();

        const response = await getGoogleUserInfo(tokenResponse.access_token);
        if (!response.data) {
            throw new Error(`Failed to fetch user info`);
        }
        if (!response.data?.hd) {
            toast.warning("You need to use a company account");
            localStorage.setItem("isAuth", JSON.stringify(false));
            setIsAuth(false);
            return;
        }

        if (response.data) {
            localStorage.setItem("userInfo", JSON.stringify(response.data));
            localStorage.setItem("isAuth", JSON.stringify(true));
            localStorage.setItem("authProvider", 'google');
            localStorage.setItem("vendor_id", response.data.hd);
        }

        const data = await googleAuth({
            mail: response.data?.email ?? '',
            access_token: tokenResponse.access_token,
            vendor_id: response?.data?.hd ?? ""
        }).unwrap();

        if (data) {
            localStorage.setItem("access_token", data.auth_token ?? '');

            if (data.api_key)
                localStorage.setItem("api_key", data.api_key);
            if (data.tenant_info.id)
                localStorage.setItem("tenant_id", data.tenant_info.id);

            setIsAuth(true);
        }
    } catch (error) {
        toast.warning(`${error}`);
    }
    })();
};

    const login = useGoogleLogin({
        onSuccess,
        flow: 'implicit',
        error_callback: (error) => {
            toast.warning(`${error.type}`);
        }
    });

    const googleLogin = useCallback(() => {
        login();
    }, [login])

    const handleSignout = useCallback(async (homeAccountId?: AccountInfo) => {
        const logoutActions: { [key: string]: () => Promise<void> | void } = {
            microsoft: () => instance.logoutRedirect({
                account: homeAccountId,
                onRedirectNavigate: () => {
                    setIsAuth(false);
                    localStorage.clear();
                    sessionStorage.clear();
                    dispatch(setSelectedAgent(null));
                    navigate(RoutesEnum.WELCOME_PAGE);
                    return false;
                }

            }),
            google: () => {
                googleLogout()
                setIsAuth(false);
                localStorage.clear();
                sessionStorage.clear();
                dispatch(setSelectedAgent(null));
                navigate(RoutesEnum.WELCOME_PAGE);
            }
        };

        try {
            if (authProvider && logoutActions[authProvider]) {
                await logoutActions[authProvider]();
            }
        } catch (error) {
            toast.warning(`Error during logout: ${error}`);
        }
    }, [authProvider, dispatch, instance, navigate]);

    return { initializeSignIn, login, isAuth, handleSignout, googleLogin, setIsAuth };
};
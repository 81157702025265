export interface IDatastores {
  data: IDatastoreResponse[];
}
export interface IDatastoreObject {
  data: IDatastoreResponse;
}
export interface IDatastoreResponse {
  id: string;
  tenant_id: TenantId;
  name: string;
  description: string;
  datastore_type: string;
  conf: IGeminiConfResponse;
  last_ingestion: string;
}
export interface IDatastoreRequest extends IDatastore {
  tenant_id: string;
}
export interface IDatastore {
  id: string
  name: string
  description: string
  datastore_type: string
  conf: IGeminiConf
}
export interface TenantId {
  id: string
  collection: string
}
export interface IGeminiConf { // configurazioni per Gemini
  project_id: string
  location_id: string
  data_store_name: string
}


export interface IGeminiConfResponse {
  project_id: string
  location_id: string
  data_store_name: string; // configurazioni per Gemini
}

export const initialDatastoreState = {
  id: '',
  name: '',
  description: '',
  datastore_type: '',
  last_ingestion: '',
  conf: {
    project_id: '',
    location_id: '',
    data_store_name: ''
  }
}

export const sortingKeys: string[] = ["id", "name", "last_ingestion", "datastore_type"]

import styles from "./DocDetails.module.scss";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { useCallback, useEffect, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { IDoc } from "../../types/IMessages";

interface IDocDetails {
  docs: IDoc[];
}

const DocDetails = ({ docs }: IDocDetails) => {
  const [hash, setHash] = useState<string>(window.location.hash);
  const disclosureBtn = useRef<HTMLButtonElement>(null);
  const disclosureItem = useRef<HTMLLIElement>(null);

  const handleHashChange = useCallback(() => {
    setHash(window.location.hash.split("#")[1] || "");
    const openCondition = docs.some((doc: IDoc) => {
      return window.location.hash.split("#")[1] === doc.anchor_id;
    });
    // Se non è aperto lo apre
    if (
      !disclosureBtn.current?.dataset.hasOwnProperty("open") &&
      openCondition
    ) {
      disclosureBtn?.current?.click();

      setTimeout(() => {
        disclosureItem?.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }, 350);
    }
  }, [docs]);

  const resetHash = useCallback(async () => {
    if (disclosureBtn.current?.dataset.hasOwnProperty("open")) {
      window.location.hash = "";
    }
  }, []);

  useEffect(() => {
    window.addEventListener("hashchange", handleHashChange);

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, [handleHashChange, hash]);

  return (
    <div className={styles.doc_details}>
      <Disclosure as="div" className={styles.disclosure} defaultOpen={false}>
        <DisclosureButton
          className={styles.disclosure_btn}
          ref={disclosureBtn}
          onClick={resetHash}
        >
          <span className={styles.disclosure_title}>Relevant documents</span>
          <ChevronDownIcon className={styles.disclosure_arrow} />
        </DisclosureButton>

        <DisclosurePanel
          transition
          className={styles.disclosure_content}
        >
          {docs.map((doc: IDoc) => (
            <li
              className={`${styles.panel_element} ${
                hash === doc.anchor_id && styles.selected
              }`}
              key={doc.id}
              id={doc.anchor_id}
              ref={hash === doc.anchor_id ? disclosureItem : null}
            >
              <h4 className={styles.doc_title} data-index={doc.index}>
                {doc.title}
              </h4>
              <a href={doc.source_uri} className={styles.doc_a} target="_blank" rel="noreferrer">
                {doc.source}
              </a>
              <ReactMarkdown
                children={doc.text || "Click on the link to learn more"}
                className={styles.doc_text}
                rehypePlugins={[rehypeRaw as any]}
              />
            </li>
          ))}
        </DisclosurePanel>
      </Disclosure>
    </div>
  );
};

export default DocDetails;

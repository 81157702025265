import {
  Button,
  Field,
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import styles from "./Sorting.module.scss";
import { ArrowDownIcon, ChevronDownIcon } from "@heroicons/react/24/solid";
import { useCallback, useEffect, useState } from "react";
import { sortObjectsByField } from "../../utils/sortUtils";
import { titleCase } from "../../utils/titleCase";

interface ISorting {
  data: any;
  setSortedData: (value: any) => void;
  options: string[];
  defaultSort: string;
}

const Sorting = ({ data, setSortedData, defaultSort, options }: ISorting) => {
  const [sortBy, setSortBy] = useState<string>(defaultSort);
  const [formattedOption, setFormattedOption] = useState<string[]>(options);
  const [toward, setToward] = useState<boolean>(true); //false: descending order - true: ascending order

  const handleSortingChange = useCallback(() => {
    setSortedData(sortObjectsByField(data, sortBy, toward));
  }, [data, setSortedData, sortBy, toward]);

  useEffect(() => {
    handleSortingChange();
  }, [toward, data, sortBy, handleSortingChange]);

  useEffect(() => {
    setFormattedOption(options.map((option) => titleCase(option)));
  }, [options]);

  return (
    <div className={styles.sorting_container}>
      <Field as="div" className={styles.field}>
        <Label className={styles.label}>Sort by: </Label>
        <Listbox
          value={sortBy}
          onChange={(value: string) => setSortBy(value)}
          as="div"
          className={styles.list_box}
        >
          <ListboxButton className={styles.list_button}>
            <span className={styles.agent_name}>{sortBy}</span>
            <ChevronDownIcon className={styles.list_icon} />
          </ListboxButton>
          <ListboxOptions anchor="bottom end" className={styles.list_options}>
            {formattedOption.map((item) => (
              <ListboxOption
                key={item}
                value={item}
                className={styles.list_option}
              >
                {item}
              </ListboxOption>
            ))}
          </ListboxOptions>
        </Listbox>
      </Field>
      <p className={styles.separator}>|</p>
      <Button
        className={styles.toward_button}
        onClick={() => setToward((prev) => !prev)}
      >
        <ArrowDownIcon
          className={`${styles.toward_icon} ${
            !toward && styles.toward_inverse
          }`}
        />
      </Button>
    </div>
  );
};

export default Sorting;

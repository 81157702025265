export interface IIngesters {
  data: IIngesterResponse[]
}
export interface IIngesterObject {
  data: IIngesterResponse;
}
export interface IIngesterResponse extends IIngester {
  id: string;
  tenant_id: TenantId;
  reader_id: ReaderId;
  datastore_id: DatastoreId;
  performing_ingestion: boolean;
}
export interface IIngesterRequest extends IIngester {
  ingester_id: string;
  tenant_id: string;
  reader_id: string;
  datastore_id: string;
}
export interface IIngester {
  name: string;
  description: string;
  cronjob: string
}
export interface TenantId {
  id: string
  collection: string
}
export interface DatastoreId {
  id: string;
  collection: string;
}
export interface ReaderId {
  id: string;
  collection: string;
}  export interface ICronejob {
  ingester_id: string
  cron_expression: string
}

export const initialIngesterState: IIngesterResponse = {
  id: '',
  name: '',
  description: '',
  tenant_id: {
    id: '',
    collection: ''
  },
  reader_id: {
    id: '',
    collection: ''
  },
  datastore_id: {
    id: '',
    collection: ''
  },
  performing_ingestion: false,
  cronjob: ''
}

export const sortingKeys: string[] = ["id", "name", "reader_id", "datastore_id", "performing_ingestion"]

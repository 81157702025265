import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

import Messages from "./Messages/Messages";
import UserInput from "./UserInput/UserInput";
import {
  IAnswer,
  IDoc,
  IMessage,
  IMessages,
  initialMessages,
} from "../../types/IMessages";
import { usePostMessageMutation } from "../../api/agentApi";
import { RootState } from "../../store/store";

import styles from "./Chatbot.module.scss";
import { useTranslation } from "react-i18next";
import { IError } from "../../types/IErrors";
import { hasDataProperty } from "../../utils/checkError";

interface IChatbot {
  isSideBarOpen: boolean;
}

const Chatbot = ({ isSideBarOpen }: IChatbot) => {
  const [postMessage, { isLoading }] = usePostMessageMutation();
  const [messages, setMessages] = useState<IMessages>(initialMessages);
  const [loading, setLoading] = useState<boolean>(true);
  const selectedAgent = useSelector(
    (state: RootState) => state.agents.selectedAgent
  );
  const { t } = useTranslation();

  const tenant_id = localStorage.getItem("tenant_id") ?? "";

  const linkNumberToDoc = useCallback((answer: string, docs: IDoc[]) => {
    let tempDocs: IDoc[] = [];
    let allIndices: number[] = [];

    const sostFun = (match: any, numberList: string) => {
      const indices = numberList
        .split(", ")
        .map((num: string) => parseInt(num, 10) - 1);
      indices.forEach((index) => {
        if (!allIndices.includes(index)) {
          allIndices.push(index);
        }
      });
      const links = indices
        .map((index: number) => `[${index + 1}](#${docs[index].anchor_id})`)
        .join(", ");
      return `[${links}]`;
    };

    const result = answer.replace(/\[(\d+(?:, \d+)*)\]/g, sostFun);

    tempDocs = allIndices.map((index) => docs[index]);

    return { formattedAnswer: result, tempDocs };
  }, []);

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  const sendMessage = useCallback(
    async (userInput: string) => {
      const nextMessageId = messages.messages.length;
      const newMessage: IMessage = {
        messageId: nextMessageId,
        message: userInput,
        user: "user",
      };

      setMessages((prevMessages) => ({
        ...prevMessages,
        messages: [...prevMessages.messages, newMessage],
      }));

      setLoading(true);

      try {
        const result: IAnswer = await postMessage({
          tenant_id: tenant_id,
          agent_id: selectedAgent?.id ?? "",
          query: newMessage.message,
        }).unwrap();

        const { formattedAnswer, tempDocs } = linkNumberToDoc(
          result.answer,
          result.docs
        );

        const newResponse: IMessage = {
          messageId: nextMessageId + 1,
          message: formattedAnswer,
          docs: tempDocs,
          user: "bot",
        };
        setMessages((prevMessages) => ({
          ...prevMessages,
          messages: [...prevMessages.messages, newResponse],
        }));
      } catch (error) {
        if (hasDataProperty(error)) {
          const typedError = error as IError;
          if (typedError.data.message) {
            toast.error(t(`errors.${typedError.data.code}`));
            toast.warning(`Details: ${typedError.data.message}`);
          } else {
            toast.error(t(`errors.${typedError.data.code}`));
          }
        } else {
          toast.error(`${t("errors.generic")}`);
        }
      } finally {
        setLoading(false);
      }
    },
    [tenant_id, linkNumberToDoc, messages.messages.length, postMessage, selectedAgent?.id, t]
  );
  return (
    <div
      className={`${styles.chatbot_container} ${isSideBarOpen && styles.chatbot_container_open
        }`}
    >
      <div className={styles.conversation_container}>
        <Messages messages={messages.messages} loading={loading} />
      </div>
      <UserInput sendMessage={sendMessage} isLoading={isLoading} />
    </div>
  );
};

export default Chatbot;
